.SetupStepBox {
  cursor: pointer;
  transition: 0.05s all;
  border-radius: var(--p-space-200);
  &.Expanded {
    background: var(--p-color-bg-surface-active);
  }
  &:hover {
    background: var(--p-color-bg-surface-active);
  }
}
