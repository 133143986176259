.DateTimePicker {
  display: flex;
  gap: var(--p-space-500);
  > div {
    .Polaris-TextField {
      cursor: pointer;

      .Polaris-TextField__Input {
        cursor: pointer;
      }
    }
  }
}
