.CartRules {
  height: 100%;
  .specificRule {
    padding: var(--p-space-300);
    border-bottom: var(--p-border-width-025) solid
      var(--p-color-border-secondary);
    cursor: pointer;
    &.noBorder {
      border: none;
    }
    &:hover {
      background: var(--p-color-bg-surface-hover);
    }
    .bullet {
      padding: var(--p-space-200) var(--p-space-800) 0;
    }
  }
}
