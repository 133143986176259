.NotificationAnimations {
  .AnimationsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .RightContent {
      width: 200px;
    }
  }
}
