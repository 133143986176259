.ConditionModal {
  width: 900px;

  .Polaris-LegacyCard__Section {
    .Title {
      margin-bottom: 0;
    }
  }

  .Polaris-LegacyStack--vertical {
    margin: 0;
    padding: 20px 20px 0;

    .Polaris-LegacyStack__Item {
      margin-top: 0;

      &:last-of-type {
        margin-top: 4px;
      }
    }
  }
}