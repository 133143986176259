.ColorStyle {
  padding: 12px;

  .ColorStyleHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    padding-left: 8px;

    .DotsButton {
      .Polaris-Button {
        border: none;
        box-shadow: none;
      }

      &.isActive {
        .Polaris-Button {
          background: #f1f2f3;
          border-radius: 2px;
        }
      }
    }
  }

  .ColorList {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
