.LineChartWrapper {
  &.minimize {
    > div {
      > div {
        min-height: 140px !important;
        > div {
          height: 140px !important;
        }
      }
    }
  }
}
