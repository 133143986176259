.DiscountSelectSection {
  .Polaris-Grid-Cell {
    & > .Polaris-ShadowBevel {
      height: 100%;
      & > .Polaris-Box {
        height: 100%;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: var(--p-space-400);
          height: 100%;
        }
      }
    }
  }
}
