.grid-color-container {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    width: 36px;
    height: 36px;
    overflow: hidden;
    border-radius: 8px;

    &.two-rows {
        grid-gap: 1px;
        grid-template-rows: repeat(2, 1fr);
    }

    .color-block {
        height: 100%;
    }
}

.flex-color-container {
    display: flex;
    flex-wrap: wrap;
    width: 36px;
    height: 36px;
    overflow: hidden;
    border-radius: 8px;

    .block {
        height: calc(50% - 0.5px);

        &:nth-child(-n + 2) {
            width: calc(50% - 0.5px);
        }

        &:nth-child(2) {
            margin-left: auto;
        }

        &:nth-child(4) {
            margin-right: auto;
            margin-left: auto;
        }

        &:nth-child(n + 3) {
            width: calc((100% - 2px) / 3);
            margin-top: auto;
        }
    }
}