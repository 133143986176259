.RightSideBar {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 360px;
  min-width: 360px;
  max-width: 360px;
  height: 100vh;
  padding-bottom: 64px;
  overflow-x: hidden;
  background: #fff;
  border-left: 1px solid #e1e3e5;

  &::-webkit-scrollbar {
    display: none;
  }

  &.DisableScrolling {
    overflow: hidden;
  }

  &.left {
    border-right: 1px solid #e1e3e5;
    border-left: 0;
  }

  .barName {
    font-weight: 600;
    font-size: 14px;

    &.left {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;

      .icon {
        margin-right: 10px;
      }
    }
  }

  .RightSideSection {
    border-bottom: 1px solid var(--p-color-border);
  }

  .EmptyRightSideBanner {
    .RightSideSection {
      padding: var(--p-space-500);
    }
  }
}
