.level {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.presetsListWrapper {
    padding: var(--p-space-400);
    border-bottom: 1px solid var(--p-color-border);

    .Polaris-Tabs__Outer {
        .Polaris-Box {
            padding: 0;

            .Polaris-Tabs {
                padding: 0;
            }
        }
    }
}

.listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--p-space-300) var(--p-space-400);
    border-bottom: 1px solid var(--p-color-border);
    cursor: pointer;

    &:hover {
        background: var(--p-color-bg-surface-hover);
    }

    .menuHorizontalIcon {
        &>div {
            display: flex;
        }
    }
}

.emptyState {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 120px;
    border-bottom: 1px solid var(--p-color-border);
}

.presetsListPagination {
    padding: var(--p-space-400);
    background: var(--p-color-bg-surface-secondary);

    .Polaris-ButtonGroup {
        justify-content: flex-end;
    }
}