.PresetChangesBadge {
  padding: var(--p-space-300);
  border-top: 1px solid var(--p-color-border-secondary);

  &.HasScroll {
    background-color: red;
  }

  .PresetBox {
    padding: var(--p-space-200);
    background: var(--p-color-bg-surface);
    border: 1px solid var(--p-color-border);
    border-radius: 8px;

    span {
      color: var(--p-color-text-secondary);
    }

    .Polaris-Button {
      box-shadow: none;
    }

    &.HasChanges {
      color: var(--p-color-text-info);
      background: var(--p-color-bg-surface-info);
      border: none;

      span {
        color: var(--p-color-text-info);
      }

      .Polaris-Button {
        background-color: transparent;
      }
    }
  }
}
