.SettingsModal {
  position: absolute;
  top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
  transition: 0.5s ease-out;
}

.Settings {
  display: flex;
  height: 100vh;
  background: var(--p-color-bg);
  flex-flow: row nowrap;
  justify-content: center;

  .SettingsPages {
    width: 100%;
    padding-top: 32px;
    &.HideNavbar {
      .Polaris-Page {
        padding-left: 0;
      }
    }

    .Polaris-Page {
      padding-right: 0;
    }

    .SettingFooterSaveButton {
      display: flex;
      justify-content: flex-end;
      padding: var(--p-space-400) 0 var(--p-space-600) var(--p-space-600);
    }
  }

  .SectionTitleBoxWithButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .PageTitle {
    padding: 24px 0;
    font-weight: 600;
    font-size: 28px;
    font-style: normal;
  }

  .PageSectionTitle {
    color: #202223;
    font-weight: 400;
    font-size: 20px;
    font-style: normal;
  }

  .PageSectionSubtitle {
    margin-top: 4px;
    color: #6d7175;
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;

    .Polaris-Button__Text {
      order: -1;
      font-size: 12px;
    }
  }
}
