.SaveWidgetEditorModalContent {
  .ChangesBadge {
    display: flex;
    gap: var(--p-space-100);
    align-items: center;
    padding: var(--p-space-100) var(--p-space-200);
    background: var(--p-color-bg-fill-transparent-secondary);
    border-radius: var(--p-border-radius-200);
  }
}
