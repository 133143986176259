.TopBarWarningText {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 20px;
    color: #d72c0d;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
}

.TopBarInfoText {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 20px;
    color: #f6f6f7;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
}