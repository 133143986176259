.OverviewOfferList {
  .OfferListItem {
    cursor: pointer;
    &.IsWarning {
      .Polaris-Box {
        background: var(--p-color-bg-surface-warning) !important;
      }
    }
    &:hover {
      .Polaris-Box {
        background: var(--p-color-bg-surface-hover);
      }
    }
  }
}
