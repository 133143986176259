$track-height: var(--track-height);
$motion-duration-50: var(--p-motion-duration-50);
$motion-duration-100: var(--p-motion-duration-100);
$motion-ease: var(--p-motion-ease);
$border-radius-100: var(--p-border-radius-100);
$color-icon-secondary: var(--p-color-icon-secondary);
$color-bg-inverse: var(--p-color-bg-inverse);
$color-bg-surface: var(--p-color-bg-surface);
$color-border-focus: var(--p-color-border-focus);

.track {
  height: $track-height;
  width: 2rem;
  background: $color-icon-secondary;
  padding: 0.25rem;
  box-shadow: inset 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background $motion-duration-50 $motion-ease;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: -0.0625rem;
    right: -0.0625rem;
    bottom: -0.0625rem;
    left: -0.0625rem;
    display: block;
    pointer-events: none;
    box-shadow: 0 0 0 -0.0625rem $color-border-focus;
    transition: box-shadow $motion-duration-100 $motion-ease;
    border-radius: calc($border-radius-100 + 0.0625rem);
  }

  &:hover {
    background: rgba(97, 97, 97, 1);
  }

  &.track_on {
    background: $color-bg-inverse;

    &:hover {
      background: rgba(48, 48, 48, 1);
    }
  }
}

.knob {
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 0.1875rem;
  background: $color-bg-surface;
  transition: transform $motion-duration-50 $motion-ease;

  &.knob_on {
    transform: translate(100%);
  }
}
