.CycleOffers {
  .CycleSelectorRow {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;

    .Polaris-TextField {
      width: 180px;
    }
  }
}
