.NinjaCartDisplayOption {
  .SimplifiedTextEntries {
    padding: 0;

    .SimplifiedTextEntriesContent {
      padding: 0;

      .EntryRow {
        gap: 16px;
        justify-content: start;
        .Polaris-Text--root {
          display: flex;
          gap: 4px;
        }

        .RightContent {
          display: inline;
        }
      }
    }
  }
}
