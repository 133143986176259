.ActivityLogPage {
  padding-bottom: var(--p-space-800);
  .logItem {
    width: 100%;
    cursor: pointer;

    &:hover {
      background: var(--p-color-bg-surface-hover);
    }

    .menuHorizontalIcon {
      & > div {
        display: flex;
      }
    }
  }

  .divider {
    margin: 0 var(--p-space-300);
  }
}
