.SettingsLayout {
  background: var(--p-color-bg);
  border-radius: 12px;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .Polaris-Page {
    .Polaris-Page__Content {
      padding: 0;
    }

    .Polaris-Layout {
      margin: 0;
    }

    .Polaris-Layout__Section {
      max-width: 100%;
      margin: 0;
    }
  }

  .SettingsLayoutHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 12px 0 8px;
    padding: 0 12px;
    background: var(--p-color-bg-surface);
    border-bottom: var(--p-border-width-025) solid
      var(--p-color-border-secondary);
    border-top-left-radius: var(--p-border-radius-300);
    border-top-right-radius: var(--p-border-radius-300);

    .HeaderTitle {
      display: flex;
      gap: 8px;
      align-items: center;
      height: 48px;
    }

    .HeaderActions {
      display: flex;
      gap: 20px;
      align-items: center;

      .CloseSettingsIcon {
        padding: 6px;
        cursor: pointer;
        transition: 0.05s all ease-in-out;

        &:hover {
          background: var(--p-color-bg-surface-hover);
          border-radius: 8px;
        }
      }
    }
  }
}
