.TranslationsComponent{
  .TranslationsLocale{
      padding: var(--p-space-400);
      border: 1px solid var(--p-color-border);
      border-radius: var(--p-space-200);
      box-shadow: initial;
      cursor: pointer;
      transition: 50ms all ease-in-out;

      &:hover{
        background-color: var(--p-color-bg-surface-hover);
      }
  }

  .Gap {
  margin-left: 8px;
}

.Link:hover {
  cursor: pointer;
}
}