$spacing: 2px;
$toggle-size-x: 64px;
$toggle-size-y: 24px;
$toggle-radius: 40px;
$toggle-background: #8C9196;
$toggle-background-on: #2C6ECB;
$pointer-background: #F7FAFC;
$pointer-radius: $toggle-radius;
$pointer-size-x: 28px;
$disabled: #BABEC3;

.switch {
  &__input {
    display: none;
  }

  &__label {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: $toggle-size-x;
    height: $toggle-size-y;
    background-color: $toggle-background;
    border-radius: $toggle-radius;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &::before {
      position: absolute;
      left: 0;
      display: block;
      width: $pointer-size-x;
      height: $toggle-size-y - $spacing * 2;
      margin: $spacing;
      background-color: $pointer-background;
      border-radius: $pointer-radius;
      transition: margin-left 0.2s ease;
      content: attr(data-label-off);
    }
  }

  &__on,
  &__off {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    color: #fff;
    font-size: 10px;
    font-family: Inter, sans-serif;
    letter-spacing: 1px;

    &::selection {
      background-color: transparent;
    }
  }

  &__on {
    left: 9px;
    visibility: hidden;
  }

  &__off {
    right: 7px;
  }

  &.disabled {
    .switch__label {
      background-color: $disabled;
    }
  }

  &--on & {
    &__on {
      visibility: visible;
    }

    /* stylelint-disable no-descending-specificity */
    &__label {
      background: $toggle-background-on;

      &::before {
        margin-left: $toggle-size-x - $pointer-size-x - $spacing;
      }
    }
  }
}