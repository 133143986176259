.ChangePlanModal {
  .ComparisonCards {
    display: flex;
    gap: var(--p-space-300);
    align-items: space-between;
  }
  .NavigationCard {
    padding: var(--p-space-400);
    border-radius: 8px;
    border: var(--size-025, 1px) solid var(--p-color-border);
    background: var(--p-color-bg-surface);
    cursor: pointer;
    &:hover {
      transition: 50ms all ease-in-out;
      background: var(--p-color-bg-surface-secondary);
    }
  }
}
