.Polaris-Navigation {
  padding: 6px;
  .Polaris-Navigation__ItemInnerWrapper--selected {
    background-color: transparent;
  }
  .Polaris-Navigation__Item--selected {
    &::after {
      background-color: transparent;
    }
  }

  &__Container {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 641px) {
      position: sticky;
      top: 0;
      left: 0;
      align-self: flex-start;
    }
    @media screen and (max-width: 640px) {
      position: sticky;
      top: 0;
      z-index: 3;
      align-self: flex-start;
      width: 100%;
    }

    nav.Polaris-Navigation {
      height: 100vh;
      min-height: 100vh;
      max-height: 100vh;
      .Polaris-Navigation {
        &__Item {
          padding: 6px;
          &--selected {
            border-radius: 4px;
            background-color: #fff;
          }
          .Polaris-Navigation__Icon svg {
            margin-left: 3px;
          }
          &:hover {
            background: #ffffff8c;
            border-radius: 4px;
          }
        }
        &__ListItem {
          margin-bottom: 4px;
          .Polaris-Navigation__Item {
            border-top: none;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    &--expanded {
      animation: slide-fwd-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }
    &--not-expanded {
      .Polaris-Navigation__Text {
        display: none;
      }
      @media screen and (min-width: 641px) {
        * {
          max-width: 60px !important;
          font-size: 0 !important;
        }
        nav.Polaris-Navigation {
          min-width: 60px;
          border-right: none;
        }
      }
    }
    @media screen and (min-width: 641px) {
      & > div.Polaris-Navigation__TopBar {
        display: none;
      }
      .overlay {
        display: none;
      }
    }
    @media screen and (max-width: 640px) {
      nav.Polaris-Navigation {
        display: none;
        border-right: none;
      }
    }
  }
}
@keyframes slide-fwd-right {
  0% {
    transform: translateZ(0) translateX(-100px);
  }
  100% {
    transform: translateZ(160px) translateX(0);
  }
}
@keyframes slide-fwd-right {
  0% {
    transform: translateZ(0) translateX(-100px);
  }
  100% {
    transform: translateZ(160px) translateX(0);
  }
}
