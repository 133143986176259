.DiscountLimits {
  .Polaris-BlockStack--listReset {
    gap: var(--p-space-200);
    .Polaris-Bleed {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .Polaris-Choice__HelpText {
        margin-bottom: 0;
      }
      .Polaris-TextField__Spinner {
        display: none;
      }
    }
  }
}
