.CornerRadius {
  .CornerRadiusHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0 8px 8px;

    .Hover {
      padding-right: 8px;
    }
  }

  .RightContent {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 200px;
  }

  .BordersRow {
    display: flex;

    >div {
      width: 20px;
      height: 20px;
      margin: 6px;
      border: 2px solid var(--p-color-icon-secondary);
      cursor: pointer;
      transition: 0.05s all ease-in-out;

      &.isDisabled {
        border: 2px solid var(--p-color-icon-disabled);
        pointer-events: none;

        &.isActive {
          border: 2px solid var(--p-color-icon-secondary);
        }
      }

      &:hover {
        border: 2px solid #44474a;
      }
    }

    .NONE {
      &.isActive {
        border: 2px solid #44474a;
      }
    }

    .CIRCLE {
      border-radius: 100%;

      &.isActive {
        border: 2px solid #44474a;
      }
    }

    .CUSTOM {
      border-radius: 6px;

      &.isActive {
        border: 2px solid #44474a;
      }
    }
  }

  .Polaris-TextField__Spinner {
    display: none;
  }
}