.widgetsControlCenterContent {
  .placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--p-color-bg-surface-brand);
    &.separate {
      .Polaris-Icon {
        width: 100%;
        height: 158px;
      }
    }
  }
}
