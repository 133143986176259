.FontSelector {
  .Loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid #aeb4b9;
    border-radius: 4px;
    box-shadow: 0 1px 0 0 rgb(0 0 0 / 5%);
  }
}
