.TextEntries {
  &.HideHeader {
    .StyleComponentWrapper {
      padding-top: 0;
    }
  }

  .GoalAndDevice {
    display: flex;
    gap: 8px;

    &.isHidden {
      display: none;
    }

    & > div {
      width: 50%;
    }
  }

  .LanguageEntry {
    margin-top: 6px;

    #language-name {
      margin-bottom: 8px;
    }
  }

  .TranslationEditor {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
