.PresetPublishModalContent {
  width: 400px;
  padding: 16px;

  .ListsBox{
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .PublishInfoTitle {
    span {
      font-weight: 550;
    }

    margin-bottom: 12px;
  }

  .AffectedPromotionList {
    display: flex;
    flex-direction: column;
    gap:8px;
    margin-left: 20px;

    .ListItem{
        display: flex;
        gap: 8px;
    }
  }
}
