.FreeGift {
  margin-bottom: 20px;

  .Polaris-Choice {
    margin-top: 20px;
    margin-left: 5px;
  }

  .Polaris-LegacyCard__Subsection {
    margin-top: 1.25rem;
    padding-top: 1.25rem;
    border-top: 1px solid rgb(225 227 229 / 100%);

    .ErrorBox {
      display: flex;
      gap: 10px;
      margin-top: 4px;
      margin-bottom: 0;
      color: #d72c0d;

      span {
        margin: 0;
      }
    }

    .GrayBoxContainer {
      margin-top: 15px;
      padding: 20px;
      background: #f6f6f7;
      border-radius: 8px;

      .removeGiftAutomatically {
        display: flex;
        flex-direction: column;

        span:last-child {
          padding-right: 10px;
          color: #6d7175;
          font-weight: 400;
          font-size: 12px;
        }
      }

      .Polaris-Checkbox__ChoiceLabel {
        margin-top: 14px;

        &:first-child {
          margin-top: 0;
        }
      }

      div:last-of-type .Polaris-Checkbox__ChoiceLabel {
        margin-top: 14px;
      }

      .Polaris-Choice__Descriptions {
        margin-left: 5px;
      }
    }

    .checkboxWrapper {
      display: flex;
      align-items: center;

      .Polaris-Choice {
        margin-top: 0;
      }

      .maximumCheckboxLabel {
        display: flex;
        flex-wrap: wrap;
        gap: 1ch;
        align-items: center;

        .NumberInputContainer {
          position: relative;
          float: right;
          min-width: 50px;

          .NumberInput {
            width: 100%;
            height: 36px;
            text-align: right;
            border-color: rgb(186 191 195 / 100%);
          }

          &.Invalid {
            .NumberInput {
              background-color: #fff4f4;
              border: 1px solid #d82c0d;

              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }

  .SelectContainer {
    margin-top: 20px;
  }

  .Polaris-Button--plain {
    margin-top: 15px;
  }

  .Polaris-TextField__Input {
    width: 100%;
  }
}

.bullet {
  padding: var(--p-space-200) var(--p-space-800) 0;
}
