.PromotionSettings {
  padding-bottom: 60px;
}

.contextualSaveBarWrapper {
  position: sticky;
  top: 0;
  z-index: calc(var(--p-z-index-1) + 2);
  width: 100%;
  height: 52px;
  overflow: hidden;

  .Polaris-Frame {
    min-height: 100%;

    .Polaris-Frame__ContextualSaveBar {
      position: absolute;
      height: 100%;

      .Polaris-Frame-ContextualSaveBar {
        height: 100%;

        &__Contents {
          min-width: auto;
          max-width: none;
          padding: 0 40px;

          .Polaris-Frame-ContextualSaveBar__ActionContainer {
            display: flex;
            align-items: center;
            height: 100%;
          }
        }
      }
    }
  }
}
