.customSelector {
  input {
    caret-color: transparent !important;
    cursor: pointer;
  }

  .Polaris-TextField__Suffix {
    position: absolute;
    right: 0;
    z-index: 11;
  }

  .Polaris-TextField {
    cursor: pointer !important;
  }
}
