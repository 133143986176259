.WidgetOptionHeader {
  padding: 12px 20px;
  border-bottom: 1px solid #e1e3e5;

  .MenuButton {
    &>div {
      display: flex;
    }
  }

  .WidgetNameBox {
    display: flex;
    gap: 12px;
    align-items: center;
    width: 90%;

    h1 {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}