.TextStyle {
  &.HideHeader {
    padding-top: 0;
  }

  .StyleComponentWrapper {
    padding-bottom: var(--p-space-300);
  }

  .TextStyleContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 var(--p-space-500) var(--p-space-500);

    .TextStyleStyleRow {
      display: flex;
      justify-content: space-between;
    }

    .TextStyleRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .RightContent {
      display: flex;
      gap: 8px;
      width: 200px;
    }

    .LeftContent {
      margin-top: 6px;
    }

    .AdancedSection {
      width: 100%;

      .Polaris-Collapsible {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .InheritCheckbox {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}