// stylelint-disable
.rteWrapper {
  position: relative;

  .rte {
    overflow: hidden;
    background: #fff;
    border: 0.66px solid #a7acb1;
    border-radius: 8px;

    &__bubbleMenu {
      display: flex;
      gap: 16px;
      align-items: center;
      padding: 8px;
      background: var(--p-color-bg-surface);
      border-radius: var(--p-border-radius-300);
      box-shadow: var(--p-shadow-300);

      &__code {
        display: flex;
        gap: 6px;
        align-items: center;

        span {
          max-width: 220px;
          overflow: hidden;
          color: var(--p-color-icon-emphasis);
          font-size: var(--p-font-size-325);
          line-height: var(--p-height-500);
          white-space: nowrap;
          text-overflow: ellipsis;
          text-decoration-line: underline;
        }
      }

      &__actions {
        display: flex;
        gap: 10px;
        align-items: center;

        .editBtn {
          padding: 5px;
          color: var(--p-color-icon-emphasis);
          font-size: var(--p-font-size-325);
          line-height: var(--p-height-500);
          background: transparent;
          border: 0;
          border-radius: var(--p-border-radius-200);
          outline: 0;
          cursor: pointer;

          &:hover {
            background: #f1f1f1;
          }
        }
      }
    }

    &__toolbar {
      padding: 6px;
      background: #f7f7f7;

      &.HideToolbar {
        padding: 0;
      }
    }

    &__toolbar-format {
      display: flex;
      flex-direction: column;

      &__line {
        display: flex;
        gap: 2px;
        align-items: center;
        margin-bottom: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #e3e3e3;
      }

      &__line:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    &__toolbar-smart-tags {
      position: absolute;
      top: -4px;
      right: 0;
      transform: translateY(-100%);
    }

    &__editor {
      padding: 12px;

      &.Disabled {
        color: var(--p-color-text-disabled);
        background-color: var(--p-color-bg-surface-disabled);
      }

      .node-la-dn-smart-tag {
        display: inline-flex;
        user-select: text !important;

        .smart-tag {
          display: inherit;
          background: transparent;
          box-shadow: none;

          .draggableTag {
            display: inherit;

            .Polaris-Tag {
              color: inherit;
              border-radius: var(--p-border-radius-100);
            }

            .Polaris-Text--truncate {
              overflow: visible;

              .Polaris-Tag__Text {
                display: flex;
                gap: 2px;
                align-items: center;

                .smartTagContent {
                  width: 65px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }

            .Polaris-Text--bodySm {
              font-weight: inherit;
              font-size: inherit;
            }
          }
        }
      }

      // when wrapper is <s>
      s .node-la-dn-smart-tag {
        text-decoration: line-through;
      }

      // when wrapper is <u>
      u .node-la-dn-smart-tag {
        text-decoration: underline;
      }

      // when wrapper is both <s> and <u>
      s u .node-la-dn-smart-tag,
      u s .node-la-dn-smart-tag {
        text-decoration: underline line-through;
      }
    }

    .ProseMirror {
      code {
        padding: 0.25em;
        color: #616161;
        font-size: 0.9rem;
        background-color: rgba(#616161, 0.1);
        border-radius: 0.25em;
        box-decoration-break: clone;
      }
    }

    .ProseMirror:focus {
      outline: none;
    }

    .ProseMirror-hideselection *::selection {
      background: Highlight;
    }

    .ProseMirror-separator {
      width: initial !important;
    }

    .cm-editor {
      outline: none !important;

      .cm-gutterElement {
        margin-top: 0;
      }

      .cm-content {
        padding: 0;
      }
    }
  }

  // get rid of if not needed
  &__character-count {
    display: flex;
    justify-content: flex-end;
    padding: 4px 0 10px;
    color: #6d7175;
    font-weight: 400;
    font-size: 12px;
    font-style: normal;

    &--invalid {
      color: var(--p-text-critical);
    }
  }
}

// get rid of if not needed
.fontSelector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  padding: 3px 9px 3px 8px;
  background: #fff;
  border: 1px solid #e1e3e9;
  border-radius: 2px;

  .fontName {
    font-size: 12px;
    line-height: 20px;
  }
}

.fontSizeBlock {
  .Polaris-Select__Input {
    padding: 0;
  }

  .Polaris-Select__Backdrop {
    background: var(--p-color-surface-tertiary);
    border: 1px solid var(--p-color-icon-disabled);
    border-radius: var(--p-border-radius-100);
  }

  .Polaris-Select__Content {
    width: 24px;
    height: 20px;
    min-height: auto;
    padding: 0;
    color: var(--p-color-icon-secondary);
    font-weight: 550;
    font-size: 12px;
    font-family: Inter, sans-serif;
    font-style: normal;
    line-height: 16px;

    .Polaris-Select__SelectedOption {
      text-align: center;
    }

    .Polaris-Select__Icon {
      display: none;
    }
  }
}

// get rid of if not needed
.linkPopover {
  .Polaris-ButtonGroup {
    justify-content: flex-end;
    margin: 10px 0 0;
    padding: 0;

    .Polaris-ButtonGroup__Item {
      margin: 0;
    }

    .Polaris-ButtonGroup__Item:last-child {
      margin: 0 0 0 5px;
    }
  }
}

.insertSmartTagWrapper {
  width: 320px;

  .insertSmartTagHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 6px 6px 12px;
    border-bottom: 1px solid var(--p-color-border);
  }

  .insertSmartTagBody {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;

    .Polaris-ButtonGroup {
      justify-content: flex-end;
      margin-top: 0;
      margin-left: 0;
      padding-top: 12px;

      .Polaris-ButtonGroup__Item {
        margin-top: 0;
      }
    }
  }
}

.rte__toolbar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: transparent;
  border: 0;
  border-radius: var(--p-border-radius-200);
  outline: 0;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 2px;
  }

  &:not(.rte__toolbar-button--active):hover {
    background: #f1f1f1;
  }

  &--active {
    background: #edeeef;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: transparent !important;
    pointer-events: none;
  }
}
