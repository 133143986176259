.CustomDeliveryMethodContainer {
    display: flex;
    flex-direction: column;

    .ValidationMsg {
      display: none;
    }

    &.NotValid {
      input {
        background: #fff4f4;
        border-color: #d82c0d;
        border-radius: 4px;
      }
  
      .ValidationMsg {
        display: flex;
        justify-content: flex-start;
        margin-top: 5px;
        color: #d72c0d;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        .Polaris-Icon {
          margin: 0 10px 0 0;
        }
      }
    }
    
  }