.FeatureListDescription {
  .FeatureRow {
    &:hover {
      .Polaris-Box {
        cursor: pointer;
        background: var(--p-color-bg-surface-hover);
      }
    }
  }
}
