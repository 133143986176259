.Polaris-Frame-Toast {
  justify-content: space-between;
  max-width: none;
  padding: 0.5rem 1rem;

  .Polaris-HorizontalStack {
    // width: calc(100% - 56px);

    .Polaris-Text--medium {
      width: 100%;
      overflow: hidden;
      font-weight: 400;
      font-size: 1rem;
      font-style: normal;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.Polaris-List {
  .Polaris-List__Item:last-child {
    margin-bottom: 0;
  }
}

.DefaultSecondaryPage {
  .Polaris-Page--fullWidth {
    width: var(--p-breakpoints-xl);
  }
}

.Polaris-TextField--hasValue {
  .Polaris-TextField__Input {
    padding-right: 0;
  }
}

// Temporary solution (Max Modal), before we set up App Bridge

.Polaris-Modal-Dialog--sizeFullScreen {
  max-width: 99% !important;
  min-height: 99vh !important;
  margin-top: 8px !important;
  background: var(--p-color-bg) !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

// Remove body focus when Modal is scrollable
.Polaris-Modal__Body:focus {
  --p-color-border-focus: null;
}

.wrapper {
  display: flex;

  &--loading {
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    padding-left: 0;
  }

  &--default {
    @media screen and (max-width: 640px) {
      flex-direction: column;
    }
  }
}

// resolving issue with two colum layout https://github.com/Shopify/polaris/issues/907
.Polaris-Page--fullWidth {
  flex: 1 1 100%;
  width: 100%;
}

.Polaris-Modal-Dialog__Container {
  z-index: 1001 !important;
}

.Polaris-Button__Content {
  width: 100%;
  height: 100%;

  .Polaris-Button__Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 20px;
    height: 100%;
    min-height: 20px;
  }

  .Polaris-Button__Text {
    white-space: nowrap;
  }
}

.NumberInputContainer {
  &.disabled {
    .NumberInput {
      color: #8c9196;
      background: #fafbfb;
      border: 1px solid #d2d5d8;
    }
  }
}

.ReactModal__Content {
  transform: translateY(100px);
  opacity: 0;
  transition: all 500ms ease-in-out;

  &:focus {
    outline: none;
  }
}

.ReactModal__Content--after-open {
  transform: translateX(0);
  opacity: 1;
}

.ReactModal__Content--before-close {
  transform: translateY(100px);
  opacity: 0;
}

.Polaris-Tooltip-TooltipOverlay--default {
  max-width: 300px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

// Fix for first column without a bottom border in Index Table component. (Remove it after Polaris fix this | for Andriy)
// --->
.Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
  :is(.Polaris-IndexTable__TableRow, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--subheader)
  .Polaris-IndexTable__TableCell:first-child {
  background-color: transparent;
}

.Polaris-IndexTable
  :is(.Polaris-IndexTable__StickyTable--scrolling.Polaris-IndexTable__Table--unselectable, .Polaris-IndexTable__Table--scrolling.Polaris-IndexTable__Table--unselectable)
  .Polaris-IndexTable__TableRow
  :is(.Polaris-IndexTable__TableHeading--second, .Polaris-IndexTable__TableCell:first-child) {
  background-color: var(--p-color-bg-surface);
}

.Polaris-IndexTable
  :is(.Polaris-IndexTable__StickyTable--scrolling.Polaris-IndexTable__Table--unselectable, .Polaris-IndexTable__Table--scrolling.Polaris-IndexTable__Table--unselectable)
  .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--subheader
  :is(.Polaris-IndexTable__TableHeading--second, .Polaris-IndexTable__TableCell:first-child) {
  background-color: var(--p-color-bg-surface-secondary);
}
// <---
