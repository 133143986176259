.EditPresetSkeletonWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  .SkeletonTopBar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 64px;
    min-height: 64px;
    padding: 0 20px;
    background: #fff;
    border-bottom: 1px solid #e1e3e5;

    .SelectOptionsWrapper {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .exitBlock {
      display: flex;
      align-items: center;

      .topBarTitle {
        display: flex;
        flex-direction: column;

        .PresetEditorTitle {
          .Polaris-Badge {
            margin-left: 12px;
            color: white;
            background-color: var(--p-color-bg-fill-magic);
          }
        }
      }
    }

    .previewControls {
      display: flex;
      gap: 8px;
      align-items: center;

      #zoom-value {
        min-width: 60px;
      }
    }

    .Polaris-Labelled--hidden {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      max-width: 280px;
      transform: translate(-50%, -50%);
    }
  }

  .PresetEditor {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
