.BogoOptionsRadio {
  display: flex;
  flex-direction: column;
  margin-top: 21px;

  .Polaris-Choice {
    margin-left: 5px;

    &:last-of-type {
      margin-top: 6px;
    }
  }

  .Title {
    margin-bottom: 16px;
    text-transform: uppercase;
  }
}
