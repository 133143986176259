.SelectOffersModal {
  .SearchfieldBox {
    padding: 20px;
  }

  .Polaris-LegacyCard__Section {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .renderedTable {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 24px;
    padding-bottom: 12px;

    .Polaris-LegacyCard__Section {
      width: 100%;
    }

    .tableLoader {
      position: absolute;
      z-index: 101;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(235 236 239 / 70%);
    }

    .emptyBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      margin: auto 0;

      &__Title {
        padding: 8px 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      .Polaris-Text--subdued {
        margin: 0 28%;
        text-align: center;
      }
    }
  }

  .paginationBlock {
    border-top: var(--p-border-width-025) solid var(--p-color-border-secondary);

    nav {
      .Polaris-ButtonGroup {
        .Polaris-ButtonGroup__Item:last-child {
          button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        .Polaris-ButtonGroup__Item:first-child {
          button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }
}
