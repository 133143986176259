.Condition {
  position: relative;
  margin-bottom: 14px;

  .inputLine {
    display: flex;
    align-items: center;
    margin-left: auto;

    .deleteIcon {
      margin-left: 8px;

      .Polaris-Icon {
        cursor: pointer;
      }
    }

    .Polaris-Labelled--hidden {
      width: stretch;
    }

    &.firstGroupAndFirstCondition {
      margin-right: 28px;
    }
  }

  .addOrConditionBtn {
    width: max-content;
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    cursor: pointer;
    text-decoration-line: underline;
  }

  .maxOrConditionsWarning {
    margin-top: 5px;
    color: #6d7175;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
  }

  .orConnection {
    position: absolute;
    top: 18px;
    left: -17px;
    width: 50px;
    height: 50px;
    border-top: 0.5px solid #abb0b5;
    border-right: none;
    border-bottom: 0.5px solid #abb0b5;
    border-left: 1px solid #abb0b5;

    .connector {
      position: absolute;
      top: 50%;
      left: -8px;
      z-index: 1;
      font-weight: 400;
      font-size: 14px;
      font-style: normal;
      text-align: center;
      background: #f6f6f7;
      transform: translateY(-50%);
    }

    .whiteSpace {
      position: absolute;
      top: -1px;
      left: 11px;
      z-index: 1;
      width: 5px;
      height: calc(100% + 2px);
      background: #f6f6f7;
    }

    &.lastConnection {
      border-bottom: 1px solid #abb0b5;
      border-bottom-left-radius: 4px;
    }

    &.firstConnection {
      border-top: 1px solid #abb0b5;
      border-top-left-radius: 4px;
    }
  }
}