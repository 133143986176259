.setupItem {
  padding: var(--p-space-300) var(--p-space-200);
  border-radius: var(--p-border-radius-200);
}

.setupItem:hover {
  background-color: var(--p-color-bg-surface-secondary);
}

.itemImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 88px;
  margin: 0 var(--p-space-600);
  border-radius: var(--p-border-radius-200);
  background-color: var(--p-color-bg-surface-brand);
}

.setupItemExpanded:hover {
  background-color: inherit;
}

.completeButton {
  width: var(--p-height-700);
  height: var(--p-height-600);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--p-color-bg-fill-brand);
}

.itemContent {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 48em) and (max-width: 61.871875em) {
  .itemImage {
    display: none;
  }
}

@media (max-width: 45.625em) {
  .itemImage {
    display: none;
  }
}
