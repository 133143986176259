.AnalyticsChatsBox {
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 1fr));
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: var(--p-space-400);
  grid-row-gap: var(--p-space-400);
  align-items: stretch;

  @media (max-width: 785px) {
    grid-template-columns: repeat(1, minmax(150px, 1fr));
  }
}
