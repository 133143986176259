.AnalyticsReportList {
  .Polaris-Spinner--sizeSmall {
    height: 20px;
  }
  .Polaris-SkeletonDisplayText__DisplayText {
    height: var(--p-space-500);
  }

  .SessionsColumn,
  .RevenueColumn,
  .OrderColumn,
  .CustomerColumn,
  .OrderValueColumn,
  .StatusColumn,
  .UsageColumn,
  .LimitColumn,
  .OrdersColumn {
    min-width: 80px;
    max-width: 80px;
  }
  .AddToCartColumn,
  .DateColumn {
    min-width: 97px;
  }
  .CheckoutColumn {
    min-width: 120px;
  }
  .PromotionCodeColumn {
    min-width: 160px;
  }
}
