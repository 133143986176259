.ToggleWidgetModal {
  width: 620px;

  .ToggleWidgetModalContent {
    max-height: 85vh;
    padding: var(--p-space-400);
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ModalFooterActions {
    padding: var(--p-space-400);
    border-top: var(--size-025, 1px) solid var(--p-color-border);
  }

  .TabNavigateCard {
    padding: var(--p-space-300) var(--p-space-400);
    border: var(--size-025, 1px) solid var(--p-color-border);
    border-radius: 12px;
    cursor: pointer;
    transition: 50ms all ease-in-out;

    &:hover {
      background-color: var(--p-color-bg-surface-hover);
    }
  }
}
