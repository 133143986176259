.DefaultStatus {
  .ScheduleSummaryList {
    position: relative;
    transition: all 0.3s ease;
    z-index: 1;
    cursor: pointer;
  }

  .ScheduleSummaryList::before {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    z-index: -1;
    opacity: 0;
    padding: var(--p-space-200);
    border-radius: var(--p-space-200);
    background: var(--p-color-bg-surface-hover);
  }

  .ScheduleSummaryList:hover::before {
    opacity: 1;
  }
}
