.RulePriority {
  .PrioritiesInfoTitle {
    margin-bottom: 4px;
    color: #202223;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
  }

  .prioritiesBlock {
    display: flex;
    width: 100%;
    padding-top: 12px;
    overflow: hidden;

    .numbers {
      display: flex;
      flex-direction: column;

      .number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 44px;
        font-weight: 600;
        font-size: 14px;
        font-style: normal;
      }
    }

    .rules {
      width: 100%;
      overflow: hidden;

      .rule {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 12px 16px;
        font-weight: 400;
        font-size: 14px;
        font-style: normal;
        white-space: nowrap;
        border-radius: 4px;

        .content {
          width: 70%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &:hover {
          background: #f1f2f3;
        }

        &.disabled {
          color: #8c9196;
        }

        .orderBtns {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;

          .btn {
            cursor: pointer;
          }

          .btn:last-of-type {
            margin-left: 12px;
          }
        }

        .dragHandleMinor {
          position: absolute;
          top: 50%;
          left: -1px;
          transform: translateY(-50%);
        }

        .link {
          font-weight: 600;
          font-size: 14px;
          font-style: normal;
          line-height: 20px;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            text-decoration-line: underline;
            text-decoration-thickness: 1.5px;
          }
        }
      }
    }
  }

  .Polaris-Banner {
    margin-top: 24px;
  }
}