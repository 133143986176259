.PageDisplay {
  .PageDisplayRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .RightContent {
      width: 160px;
    }
  }

  .DetailsSection {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .PageList {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }

  .SelectProducts {
    .ShopifyAppBridgeModalInitiatorContainer {
      margin-top: 12px;

      & > p {
        display: none;
      }
    }
  }
}
