.OfferTemplate {
  width: 100%;
  padding: var(--p-space-400);
  background: var(--p-color-bg-surface);
  border: 1px solid var(--p-color-border);
  border-radius: var(--p-space-300);
  cursor: pointer;

  &:hover {
    background: var(--p-color-bg-surface-hover);
  }

  &.Active {
    background: var(--p-color-bg-surface-hover);
  }
}
