.ResourceListCountry {
  border-bottom: 0.0625rem solid var(--p-border-subdued);

  &:last-of-type {
    border: 0;
  }

  &__Parent {
    display: flex;
    align-items: center;
    padding: 1rem;

    label {
      pointer-events: none;
    }

    & > .Polaris-InlineStack {
      width: 100%;

      .rightContent {
        display: inline-flex;
        align-items: center;
        gap: var(--p-space-300);
        margin-left: auto;
        .Polaris-Button {
          padding: 0;
        }
      }
    }

    &:hover {
      background-color: var(--p-surface-hovered, #f4f6f8);
      cursor: pointer;
    }
  }

  &__Variant {
    display: flex;
    align-items: center;
    padding: 1rem 1rem 1rem 3rem;
    border-top: 0.0625rem solid var(--p-border-subdued);

    label {
      pointer-events: none;
    }

    &:hover {
      background-color: var(--p-surface-hovered, #f4f6f8);
      cursor: pointer;
    }
  }
}
