.LeftSidebar {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 360px;
  min-width: 360px;
  max-width: 360px;
  height: 100vh;
  padding-bottom: 64px;
  background: #fff;
  border-right: 1px solid #e1e3e5;

  .LeftSidebarContent {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 12px;
    padding-bottom: 10px;
  }

  .OptionWrapper {
    position: relative;
  }

  .LeftMenuSplitter {
    position: relative;
    height: 1px;
    margin: 12px 0 8px;

    &::before {
      position: absolute;
      left: -12px;
      width: calc(100% + 24px);
      height: 1px;
      background-color: var(--p-color-border);
      content: '';
    }
  }

  .ScrollableContent {
    height: 100%;
  }

  .LeftSidebarHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px;
    border-bottom: 1px solid var(--p-color-border-secondary);

    .HeaderName {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  .WidgetOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: stretch;
    margin-top: var(--p-space-100);
    padding: 0 var(--p-space-100);
    border-radius: var(--p-space-200);

    .AddBlockButton {
      display: flex;
      align-items: center;
      height: 36px;
      margin-left: 28px;

      & > div {
        display: flex;
        align-items: center;
      }
    }

    .WidgetOptionSide {
      display: flex;
      gap: 8px;
      align-items: center;
      width: 100%;
      height: 36px;

      .WidgetName {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.Disabled {
          color: var(--p-color-text-disabled);
        }
      }

      .HideDeleteActions {
        display: none;
        align-items: center;

        & > div {
          display: flex;
          align-items: center;
          width: 28px;
          height: 28px;
          opacity: 0;
          transition: all 0.1s ease-in-out;

          &:hover {
            background: #ebebeb;
            border-radius: 8px;
          }
        }
      }
    }

    &.SecondLvlNesting {
      margin-left: 28px;
    }

    &.ThirdLvlNesting {
      margin-left: 56px;
    }

    &.isSelected {
      background: var(--p-color-bg-surface-hover);

      &::before {
        position: absolute;
        left: -9px;
        width: 3px;
        height: 36px;
        background: var(--p-color-bg-inverse);
        border-radius: 0 3px 3px 0;
        content: '';
      }
    }

    &:hover {
      background: var(--p-color-bg-surface-hover);
      cursor: pointer;
      transition: all 0.1s ease-in-out;

      .HideDeleteActions {
        display: flex;

        .ViewOptionIcon {
          opacity: 100;
        }

        .DeleteOptionIcon {
          opacity: 100;
        }
      }
    }

    .WidgetChevron {
      display: flex;
      align-items: center;
      height: 36px;
      transition: all 0.1s ease-in-out;

      &:hover {
        background: #ebebeb;
        border-radius: 8px;
      }
    }

    .WidgetOptionIcon {
      display: flex;
      align-items: center;
      height: 36px;
      transition: all 0.1s ease-in-out;

      &.DruggableHovered {
        background-color: #ebebeb;
        border-radius: 8px;
      }
    }
  }
}
