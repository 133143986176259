.MultipleDiscountsHelp {
    display: flex;
    flex-direction: column;
    margin-left: 28px;
    padding: 20px;
    background: #F6F6F7;
    border-radius: 8px;

    .Polaris-ChoiceList__Title {
        font-weight: 500;
    }

    .Polaris-ChoiceList__Choices {
        padding: 5px;
        padding-bottom: 0;
    }

    .Polaris-ChoiceList__ChoiceItem {
        padding-bottom: 5px
    }

    .Warning {
        display: flex;
        align-items: flex-start;
        margin: 0 36px;
        color: #916A00;
        font-size: 14px;

        .Polaris-Icon {
            margin: 2px 7px 0 0;
        }
    }

    .Polaris-Button {
        justify-content: start;
        width: fit-content;
        margin-top: 5px;
    }

    .Polaris-Button__Text {
        margin-left: 5px;
    }
}