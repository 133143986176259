// change
.stickyBarWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    order: 0;
    width: 100%;
    overflow: hidden;

    &.Bottom {
        position: absolute;
        bottom: 0;
    }

    &.Order {
        order: 1;
    }

    .content {
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        .bodyText {
            width: 100%;
            font-weight: 500;
            font-size: 12px;
            text-align: center;
        }

        .imageWrapper {
            height: 100%;
            padding: 5px;

            .imageLogo {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        .ctaBtnWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            margin-right: 25px;

            .ctaBtn {
                margin: 0 5px;
                padding: 6px 8px;
                font-size: 12px;
                text-align: center;
            }
        }

        &.Narrow {
            max-width: 700px;
        }
    }

    .backGroundImageOverlay {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .closeBtn {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
    }
}