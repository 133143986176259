.SelectOptions {
  &.HideCaret {
    input {
      caret-color: transparent !important;
      cursor: pointer;
    }
  }

  .Polaris-TextField {
    cursor: pointer !important;
  }

  .Polaris-TextField__Spinner {
    display: none;
  }
}
