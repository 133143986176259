.StyleComponentWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--p-space-300);
  padding: 20px;

  &.RemoveTopPadding {
    padding-top: 0;
  }

  .SettingRow {
    display: flex;
    gap: var(--p-space-300);
    align-items: center;
    justify-content: space-between;

    .RightContent {
      display: flex;
      gap: var(--p-space-200);
      align-items: center;
      width: 200px;
    }
  }

  .StyleComponentHeader {
    display: flex;
    justify-content: space-between;

    .MenuButton {
      &>div {
        display: flex;
      }
    }

    .TipAndMoreActions {
      display: flex;
      gap: var(--p-space-300);
      align-items: center;
    }
  }
}