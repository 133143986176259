.Combinations {
    margin-bottom: 20px;

    .CombinationsCheckboxInfo {
        display: flex;
        flex-direction: column;
        margin-left: 28px;

        .Interactive {
            color: #2C6ECB;
            cursor: pointer;
        }
    }
}