.SettingsNavBar {
  position: relative;
  top: 32px;
  min-width: 240px;
  height: fit-content;

  &.FullScreen {
    width: 100%;
    .NavigationCard {
      position: static;
      max-width: 100%;
      width: 100%;
    }
  }

  .NavigationTab {
    display: flex;
    justify-content: space-between;
    padding: var(--p-space-100) var(--p-space-200);
    cursor: pointer;
    border-radius: var(--p-border-radius-200);
    &:hover {
      transition: all 0.1s;
      background: var(--p-color-bg);
    }
    &.isActive {
      background: var(--p-color-bg);
    }
  }

  .NavigationCard {
    position: fixed;
    min-width: 240px;
    // max-width: 240px;
    height: auto;
    min-height: auto;
    overflow: hidden;
  }
}
