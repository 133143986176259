.RequestWidgetSupport {
  .ThemeSelectorInputBox {
    display: flex;
    gap: var(--p-space-300);
    width: 100%;

    > div {
      width: 100%;
    }
  }

  .ThemeSelectorInput {
    input {
      cursor: pointer;
    }

    .Polaris-TextField {
      cursor: pointer;
    }
  }
}
