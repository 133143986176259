.OfferContentContainer {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  padding-bottom: 160px;

  & > div.Polaris-LegacyCard {
    margin: 0 0 20px;
    border-radius: 4px;
  }

  h4 {
    color: #202223;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }

  .Polaris-LegacyCard__Section {
    &:nth-of-type(1) {
      padding: 20px 28px;
    }

    &:not(:first-of-type) {
      padding: 28px;
    }
  }
}

.TopBarContainer {
  position: sticky;
  top: 0;
  z-index: calc(var(--p-z-index-1) + 2);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 40px;
  background: #fff;
  box-shadow: 0 2px 1px rgb(0 0 0 / 5%), 0 0 1px rgb(0 0 0 / 25%);

  .Polaris-ButtonGroup .Polaris-ButtonGroup__Item:nth-of-type(2) button {
    padding: 13px 24px;
  }

  .TopBarTitle {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: default;

    .Polaris-Text--semibold {
      margin-left: 8px;
    }
  }

  button.Polaris-Button.Polaris-Button--primary:not(.Polaris-Button--disabled) {
    color: #fff;
  }
}

.TileSubTitle {
  margin: 0 0 13px;
  color: #202223;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}

.TileTitle {
  margin: 0 0 4px;
  color: #202223;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

.TileDescription {
  color: #6d7175;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
