.PromotionalBadgeOffer{
    .AdvancedSection{
        display: flex;
        justify-content: space-between;
        padding: 20px;

        .AdvancedChevron{
            cursor: pointer;
        }
    }
}