.Stitch {
  .StitchRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .RightContent {
      width: 200px;

      .LineWeightInputs {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }

    .LineWeightBox {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .Polaris-Text--root {
        margin-top: 5px;
      }
    }
  }
}