.PlanComparisonCard {
  width: 100%;
  border-radius: var(--p-space-300);
  border: 1px solid var(--p-color-border-inverse);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.isSelected {
    border: 1px solid var(--p-color-border);
  }
  #PlanMonthPrice {
    span {
      padding-right: 4px;
    }
  }
}
