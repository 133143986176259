.ImageIconLibrary {
  width: 284px;
  height: 100%;

  .IconLibraryHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 6px 6px 12px;
    border-bottom: 1px solid #e1e3e5;

    .Polaris-Button {
      border: none;
      box-shadow: none;
    }
  }

  .IconLibraryContent {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 12px;

    .LibraryList {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;

      .LibraryListItem {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        background: var(--p-color-bg-surface-secondary);
        border: var(--size-0, 1px) solid var(--p-color-border);
        border-radius: 8px;
        cursor: pointer;

        img {
          width: 32px;
          height: 32px;
        }

        .DeleteIcon {
          position: absolute;
          top: 2px;
          right: 2px;
          display: none;
          background: var(--p-color-bg-surface-secondary);
          border-radius: 8px;
          cursor: pointer;
        }

        &:hover {
          background: var(--p-color-bg-surface-secondary-hover);
          transition: 80ms all ease-in-out;

          .DeleteIcon {
            display: flex;
          }
        }

        &.IsSelected {
          background: var(--p-color-bg-surface-selected);
          border: var(--size-050, 2px) solid var(--p-color-border-focus);
        }
      }

      .EmptyCustomLibrary {
        color: var(--p-color-text-secondary);

        span {
          color: var(--p-color-text);
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .CustomLibraryHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .CustomActions {
        display: flex;

        & > div {
          padding: 6px;
          cursor: pointer;

          &:hover {
            background: var(--p-color-bg-surface-selected);
            border-radius: 8px;
          }
        }
      }
    }
  }
}
