.CreateLinkModal {
  width: 100%;
  max-width: 650px;

  .ShopifyAppBridgeModalInitiatorContainer {
    margin-top: 0;
  }

  .LinkPreviewText {
    max-width: 80%;
    word-wrap: break-word;
  }
}

.LinkPortal {
  & > div {
    // z-index: 517;
  }
}
