.InputWithTagsWrapper{
    .Title{
        margin-bottom: 4px;
    }

    .InputWithTags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        min-height: 38px;
        padding: 4px 12px;
        background: #FFF;
        border: 1px solid var(--color-input-border, #8A8A8A);
        border-radius: 8px;
    
        input {
            flex: 1;
            font-size: 14px;
            line-height: 20px;
            border: none;
    
            &:focus-visible {
                outline: none;
            }
        }
    
        &:focus-within {
            box-shadow: 0 0 0 0.125rem var(--p-focused);
        }
    
        &.Invalid {
            background: #FFF4F4;
            border-color: #FD5749;
    
            input {
                background: #FFF4F4;
            }
    
            &:focus-within {
                box-shadow: none;
            }
        }
    
        .Polaris-Tag__TagText {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
    
        .ShopifyAppBridgeModalInitiatorSearchFieldTag {
            margin: 0;

            .Polaris-Icon {
                cursor: pointer;
                fill: #5C5F62;
            }
        }
    
        
    }
    
    .InputWithTagsErrorBox {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        color: #D72C0D;
    
        span {
            margin: 0;
        }
    }
}
