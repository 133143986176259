.CustomModalPortal {
  & > div {
    z-index: 399;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.CustomModal {
  display: inline-block;
  width: fit-content;
  border-radius: 0;

  .Polaris-ButtonGroup {
    justify-content: end;
  }

  .Polaris-Banner .Polaris-ButtonGroup {
    justify-content: flex-start !important; /* or the default value you'd prefer */
  }

  .Title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    h4 {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
    }

    .Polaris-Icon {
      margin: 0;
    }
  }

  .Buttons {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;

    .ModalActionButton {
      &.HasSuffixIcon {
        .Polaris-Button__Content {
          display: flex;
          flex-direction: row-reverse;

          .Polaris-Icon {
            margin-left: 8px;
          }
        }
      }
    }
  }

  &:focus-visible {
    outline: none;
  }

  .Polaris-LegacyCard {
    position: relative;

    & > .Polaris-LegacyCard__Section {
      &:first-child {
        background-color: #f3f3f3;
        border-bottom: 1px solid var(--p-color-border);
      }

      &:last-child {
        border-top: 1px solid var(--p-color-border);
      }
    }
  }
}

.CustomModalOverlay {
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 50%);
}
