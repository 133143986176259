@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
}

html,
body {
  height: 100%;

  .ReactModal__Body--open {
    overflow: hidden;
  }

  --pc-app-provider-scrollbar-width: 0;
}
