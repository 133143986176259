.Rules {
  .rulesWrapper {
    .RuleComponent:last-of-type {
      margin-bottom: 0;
    }
  }

  .Polaris-LegacyCard__Subsection {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #e1e3e5;
  }

  .Polaris-Banner.Polaris-Banner--statusInfo {
    margin-top: 20px;
    border: 1px solid #98c6cd;
    border-radius: 8px;

    .header {
      margin-bottom: 4px;
      font-weight: 600;
      font-size: 16px;
      font-style: normal;
    }

    .body {
      font-weight: 400;
      font-size: 14px;
      font-style: normal;
    }
  }

  .errorBoxWrapper {
    margin: 12px 0 20px;

    .errorBox {
      display: flex;
      gap: 10px;
      margin-bottom: 8px;
      color: #d72c0d;

      span {
        margin: 0;
      }
    }
  }
}