.PromotionSetupGuide {
  .stepsWrapper {
    position: relative;
    margin: 0 var(--p-space-500);
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: var(--p-space-100);
      background-color: var(--p-color-bg-surface);
      border-radius: var(--p-border-radius-full);
      border: 1px solid var(--p-color-bg-surface-brand);
      z-index: 1;

      &.isActive {
        border-color: var(--p-color-bg-fill-brand-active);
      }
      &.pointerCursor {
        cursor: pointer;
      }
    }

    .Polaris-Divider {
      position: absolute;
      top: 15px;
      left: 18px;
      width: calc(100% - 26px);
    }
  }
}
