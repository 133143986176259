.Shipping {
  margin-bottom: 20px;

  .ActionGroupSubtitle {
    margin-top: 4px;
    color: #6D7175;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  .Warning {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 18px;
    color: #2C6ECB;
    cursor: pointer;

    .Polaris-Icon {
      margin: 0 7px 0 0;
    }
  }

  .SelectCountriesInputContainer {
    margin-top: 28px;

    label {
      color: #202223;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .ThresholdInputContainer {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    width: calc(100%);
    margin-top: 28px;

    label {
      color: #202223;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    input.NumberInput {
      width: 100%;
      margin-bottom: 13px;
      padding: 8px 12px;
      color: #202223;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      background: #FFF;
      border: 1px solid #A7ACB1;
      border-radius: 4px;
    }

    .ThresholdInfoText {
      color: #6D7175;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

    .ValidationMsg {
      display: none;
    }

    &.NotValid {
      input.NumberInput {
        background: #FFF4F4;
        border-color: #D82C0D;
      }

      .ValidationMsg {
        display: flex;
        justify-content: flex-start;
        color: #D72C0D;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        .Polaris-Icon {
          margin: 0 10px 0 0;
        }
      }
    }
  }

  .Polaris-LegacyStack--vertical {
    margin-top: 10px;

    .Polaris-LegacyStack__Item {
      gap: 8px;
      margin: 0;
      padding: 4px;
    }
  }
}