.myWidgets {
  .emptyBlock {
    > .Polaris-Box {
      padding: 0;
    }
  }
  .Polaris-ResourceList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--p-space-400);
  }
  .Polaris-ResourceList__FiltersWrapper {
    padding: 0;

    .Polaris-Tabs__Outer {
      > .Polaris-Box {
        padding: 0;
        .Polaris-Tabs {
          padding: 0;
        }
      }
    }
  }

  .Polaris-ResourceList__FiltersWrapper
    + .Polaris-ResourceList__ResourceListWrapper
    > :first-child:is(.Polaris-ResourceList) {
    border-top: 0;
  }
}
