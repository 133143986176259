.navigate {
  display: flex;
  gap: var(--p-space-300);
  align-items: center;
  cursor: pointer;
}


  .cardWrapper {
    .Polaris-Box {
      padding: var(--p-space-400) var(--p-space-300);
    }

    .level {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .shopLevelDefaultStyle {
      margin-top: 16px;
      padding-left: calc(var(--p-space-300) + 20px);

      .Polaris-Box {
        margin-top: 8px;
        padding: var(--p-space-300) var(--p-space-400);
      }
    }
  }

