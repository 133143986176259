.ColorSelectorBox {
  display: flex;
  gap: 8px;
  align-items: center;

  .Polaris-TextField {
    width: 80px;
  }

  .ColorBox {
    cursor: pointer;
    width: 32px;
    height: 32px;
    border: 1px solid #aeb4b9;
    border-radius: 4px;
  }
}
