.Offset {
  .OffsetRow {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
  }

  .RightContent {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 200px;
  }
}