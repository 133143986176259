.SettingsSkeleton {
    &>.Polaris-BlockStack>.Polaris-Box {
        padding-right: 0;
    }

    &>.Polaris-BlockStack>.Polaris-Box>.Polaris-BlockStack>.Polaris-Box {
        &:last-child {
            padding-bottom: 0;
        }
    }
}