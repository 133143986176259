.Size {
  .SizeRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .RightContent {
      display: flex;
      gap: 8px;
      align-items: center;
      width: 200px;
    }
  }

  .BreakPoints {
    .BreakPointRow {
      display: flex;
      gap: 8px;
      align-items: flex-end;

      .Polaris-Label__Text {
        color: #6d7175;
        font-size: 11px;
      }
    }

    .BreakPointsList {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: baseline;
      margin-top: 4px;

      .Polaris-TextField__Spinner {
        display: none;
      }

      .Polaris-Button--plain {
        padding-left: 0;
      }

      .RemoveBreakpoint {
        .Polaris-Button {
          border: none;
          box-shadow: none;
          opacity: 0;
        }

        &:hover {
          .Polaris-Button {
            opacity: 100;
          }
        }
      }
    }
  }

  .MarginControlsWrapper {
    display: flex;
    justify-content: right;
    width: 100%;
  }

  .MarginControls {
    display: flex;
    gap: 8px;
    width: 110px;

    .MarginIcon {
      padding: 6px;
      border-radius: 8px;
      cursor: pointer;

      &.Enabled {
        background: var(--p-color-bg-surface-selected);
        ;
      }

      &.Disabled {
        cursor: default;
      }
    }
  }

  .CustomMarginBox {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .MarginSideBox {
      position: relative;
      width: 64px;
      margin-right: 20px;

      img {
        position: absolute;
        top: 6px;
        left: -23px;
      }
    }
  }
}