.DaysSelectorOptionBox {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* 7 columns for a week */
  width: max-content;
  row-gap: 1px;
  column-gap: 1px;

  .DaysSelectorOption {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 35px;
    max-height: 30px;
    width: 35px;
    height: 30px;
    cursor: pointer;
    border-radius: var(--p-border-radius-200);

    &.IsSelected {
      background: var(--p-color-bg-fill-brand-active);
      color: var(--p-color-text-inverse);
    }
  }
}
