.DeletePromotionModalContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 480px;
    padding: 40px;
    text-align: center;

    h1{
        margin-top: 20px;
        margin-bottom: 12px;
        font-weight: 400;
        font-size: 26px;
        line-height: 32px;
    }

    p{
        line-height: 20px;
    }

    .Polaris-Icon{
        width: 50px;
        height: 50px;
    }
}