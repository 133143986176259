.OverviewPromotionSettings {
  .SettingHoverableBox {
    position: relative;
    padding: var(--p-space-200);
    &:hover {
      border-radius: var(--p-space-200);
      background-color: var(--p-color-bg-surface-hover);
      .EditIcon {
        opacity: 100;
      }
    }
    .EditIcon {
      opacity: 0;
      position: absolute;
      right: var(--p-space-200);
      top: var(--p-space-200);
    }
  }
}
