.StyleSetting {
  .StyleCardBox {
    padding: var(--p-space-300) var(--p-space-400);
    border: var(--size-025, 1px) solid var(--p-color-border);
    border-radius: 12px;

    .CardImage {
      width: 36px;
      height: 36px;
    }
  }
}
