.RuleComponent {
  margin-bottom: 20px;
  overflow: hidden;

  .RuleWrapper {
    width: 100%;
    background: #f6f6f7;
    border-radius: 4px;

    &.AiSuggestionsRule {
      background: rgb(159 93 225 / 8%);
      border: 1px solid #9f5de1;
    }

    .RuleTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 12px 20px;
      cursor: pointer;

      .switch {
        margin-left: auto;
      }

      .ruleName {
        display: flex;
        align-items: center;
        width: 100%;

        img {
          margin-right: 4px;
        }

        .number {
          font-weight: 600;
          font-size: 14px;
          font-style: normal;
          white-space: nowrap;
        }

        .inputWrapper {
          position: relative;
          width: calc(100% - 60px);
          height: 20px;
          margin-left: 5px;
          overflow-x: hidden;

          .input {
            position: absolute;
            z-index: 2;
            min-width: 140px;
            height: 100%;
            font-weight: 400;
            font-size: 14px;
            background-color: transparent;
            background-image: none;
            border: none;
            outline: none;
            box-shadow: none;
            cursor: text;
          }

          .placeholder {
            position: absolute;
            z-index: 1;
            display: none;
            min-width: 140px;
            height: 100%;
            color: #8C9196;
            font-weight: 400;
            font-size: 14px;
          }

          .input:empty+.placeholder {
            display: inline;
          }
        }
      }

      .TitleTextContainer {
        flex: 1;
      }

      .Polaris-Icon {
        margin-right: 0;
        margin-left: 14px;
        transition: transform 0.2s ease-in-out;
      }

      &.RuleOpen {
        .Polaris-Icon {
          transform: rotate(-180deg);
        }
      }
    }
  }
}

#rule-collapsible {
  .groups {
    padding: 20px 12px 20px 20px;
    border-top: 1px solid #e1e3e5;
  }

  .proposedItemsBlock {
    padding: 20px;
    border-top: 1px solid #e1e3e5;

    .title {
      font-weight: 600;
      font-size: 12px;
      font-style: normal;
      line-height: 16px;
      text-transform: uppercase;
    }

    .ShopifyAppBridgeModalInitiatorContainer {
      margin-top: 18px;
    }

    .Polaris-Choice {
      margin-top: 20px;
      margin-left: 5px;
    }

    .SelectContainer {
      margin-top: 20px;
    }
  }

  .mainOperationBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-top: 1px solid #e1e3e5;

    .TrashBlock {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #d82c0d;
      cursor: pointer;

      &.Disable {
        color: #8c9196;
        cursor: not-allowed;
      }

      span {
        margin-left: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .actionTypeBlock {
    padding: 20px;
    border-top: 1px solid #e1e3e5;

    .title {
      margin-bottom: 9px;
      font-weight: 600;
      font-size: 12px;
      font-style: normal;
      line-height: 16px;
      text-transform: uppercase;
    }

    .checkbox {
      margin-left: 5px;
      padding-top: 4px;
    }

    .Polaris-Banner {
      margin-top: 12px;
    }

    .radioBtns {
      display: flex;
      flex-direction: column;

      .Polaris-Choice {
        display: flex;
        align-items: center;
        margin-left: 5px;
        padding: 7px 0;

        .Polaris-Choice__Label {
          .label {
            display: flex;
            align-items: center;

            span {
              display: flex;
              align-items: center;
            }

            .tooltip {
              margin-left: 6px;
            }
          }
        }
      }
    }
  }

  .suggestedItemsBlock {
    padding: 20px;
    border-top: 1px solid #e1e3e5;

    .title {
      margin-bottom: 4px;
      color: #202223;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
    }

    .description {
      color: #6d7175;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

#ai-suggestions-rule-collapsible {
  .content {
    padding: 0 20px 20px 38px;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
  }
}