.SearchFieldWithTags {
  .Title {
    margin-bottom: 4px;
  }

  .ShopifyAppBridgeModalInitiatorContainer {
    margin-top: 0;
  }

  .ShowMore {
    cursor: pointer;
  }
}