.presetPriceWidgetWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 12px;

  .presetPriceWidgetTitle {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
    color: var(--p-color-text-secondary);
    font-size: var(--p-font-size-325);
  }
}

.DevicePreviewWrapper {
  width: 100% !important;
  cursor: grab;

  &.IsPriceWidgetType {
    background: var(--p-color-bg-surface);
    border-radius: var(--p-border-radius-200);
  }

  &.IsPan {
    cursor: grabbing;
  }
}

.DevicePreviewContent {
  &.Active {
    width: 100%;
    height: 100%;

    div:first-child {
      width: 100%;
      height: 100%;
    }
  }
}

la-dn-offer-rules-popup {
  cursor: pointer;
}