.Image {
  .ImageHeader {
    margin-bottom: 12px;
    padding: 8px 0 8px 8px;
  }

  .ImageRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .RightContent {
      display: flex;
      gap: 8px;
      align-items: center;
      width: 200px;

      .PrefixImage {
        width: 20px;
        height: 20px;
        margin-top: 5px;
      }
    }
  }
}

.EditPresetImagePreview {
  display: flex;
  gap: 12px;
  justify-content: center;
  padding: 12px;
}