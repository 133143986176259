.FirstLineContainer {
  display: flex;
  flex-direction: column;

  .ErrorBox {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    color: #D72C0D;

    span {
      margin: 0;
    }
  }
}