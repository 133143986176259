.threeCardsLayout {
  .imageCard {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--p-color-bg-surface-brand);
  }
  .Polaris-LegacyCard {
    height: 100%;
    .Polaris-MediaCard__MediaContainer {
      height: 158px;
    }
    .Polaris-MediaCard__InfoContainer {
      height: calc(100% - 158px);
      .Polaris-Box {
        height: 100%;
        .Polaris-BlockStack {
          height: 100%;
          gap: unset;
          p {
            margin: var(--p-space-200) 0 auto;
          }
        }
      }
    }
  }
}
