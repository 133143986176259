.RuleAdvancedTitle {
  display: flex;
  cursor: pointer;

  .TitleTextContainer {
    flex: 1;
  }

  .Polaris-Icon {
    transition: transform 0.2s ease-in-out;
  }

  &.RuleAdvancedOpen {
    .Polaris-Icon {
      transform: rotate(-180deg);
    }
  }
}

#rule-advanced-section-collapsible {
  .block {
    padding: 24px 28px;
    border-top: 1px solid #e1e3e5;

    .title {
      margin-bottom: 9px;
      font-weight: 600;
      font-size: 12px;
      font-style: normal;
      line-height: 16px;
      text-transform: uppercase;
    }

    .checkbox {
      margin-left: 5px;
      padding-top: 4px;
    }

    .Polaris-Banner {
      margin-top: 12px;
    }

    .radioBtns {
      display: flex;
      flex-direction: column;

      .Polaris-Choice {
        display: flex;
        align-items: center;
        margin-left: 5px;
        padding: 7px 0;

        .Polaris-Choice__Label {
          .label {
            display: flex;
            align-items: center;

            span {
              display: flex;
              align-items: center;
            }

            .tooltip {
              margin-left: 6px;
            }
          }
        }
      }
    }
  }
}