.PlanDetails {
  padding-bottom: var(--p-space-1000);
  .IntervalButtons {
    position: relative;
  }
  .GreenArrowSave {
    display: flex;
    align-items: start;
    position: absolute;
    gap: var(--p-space-100);
    right: -95px;
    top: -20px;
    p {
      position: relative;
      bottom: 10px;
    }
  }
  #PlanMonthPrice {
    span {
      padding-right: 4px;
    }
  }
}
