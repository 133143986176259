.ToggleWidgetManually {
  .ListOfNonCss {
    color: var(--p-color-text-secondary);

    ul {
      margin-left: 20px;
    }

    span {
      font-weight: 600;
    }
  }
}
