.ApplyInputNumberTextFieldWrap {
    width: 15%;
    margin: 0 var(--p-space-200);

    .Polaris-TextField__Spinner {
        display: none;
    }
}

.ApplyButtons {
    margin: 0 var(--p-space-200);
}