.PromotionDetails {
  .TokenHover {
    .CopyIcon {
      display: none;
    }
    &:hover {
      .CopyIcon {
        display: flex;
      }
    }
  }
}
