.WidgetCard {
  display: flex;
  flex-direction: column;
  height: 100%;

  &.isEmpty {
    .Polaris-MediaCard__InfoContainer .Polaris-Box .Polaris-BlockStack {
      p {
        margin: 0;
      }

      .Polaris-InlineStack > div {
        width: 100%;
      }
    }
  }
  #widgetCardPrimaryBtn {
    --pc-button-bg-gradient: var(--p-color-button-gradient-bg-fill);
    --pc-button-box-shadow: var(--p-shadow-button-primary);
    --pc-button-box-shadow_active: var(--p-shadow-button-primary-inset);
    --pc-button-bg: var(--pc-button-bg-gradient), var(--p-color-bg-fill-brand);
    --pc-button-bg_hover: var(--pc-button-bg-gradient),
      var(--p-color-bg-fill-brand-hover);
    --pc-button-bg_active: var(--pc-button-bg-gradient),
      var(--p-color-bg-fill-brand-active);
    --pc-button-bg_disabled: var(--p-color-bg-fill-brand-disabled);
    --pc-button-color: var(--p-color-text-brand-on-bg-fill);
    --pc-button-color_disabled: var(--p-color-text-brand-on-bg-fill-disabled);
    --pc-button-icon-fill: var(--p-color-text-brand-on-bg-fill);
    --pc-button-icon-fill_disabled: var(
      --p-color-text-brand-on-bg-fill-disabled
    );
  }
  .customBlockStack {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--p-space-400);
  }

  .placeholder {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: var(--p-color-bg-surface-brand);

    .statusBar {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      width: 100%;
      height: var(--p-height-600);
      padding: 0 var(--p-space-400);
      font-size: var(--p-font-size-300);

      &.info {
        background-color: var(--p-color-bg-surface-info-hover);
        color: var(--p-color-text-info-secondary);
      }

      &.default {
        background-color: var(--p-color-bg-fill-transparent-secondary);
        color: var(--p-color-text-secondary);
      }

      &.success {
        background-color: var(--p-color-bg-fill-success-secondary);
        color: var(--p-color-text-success);
      }
    }

    .badge {
      position: absolute;
      top: var(--p-space-300);
      left: var(--p-space-300);
    }
  }

  .Polaris-LegacyCard {
    height: 100%;

    .Polaris-MediaCard__MediaContainer {
      height: 158px;
    }

    .Polaris-MediaCard__InfoContainer {
      height: calc(100% - 158px);

      .Polaris-Box {
        height: 100%;

        .Polaris-BlockStack {
          height: 100%;
          gap: unset;

          p {
            margin: var(--p-space-200) 0 auto;
          }
        }
      }
    }
  }
}
