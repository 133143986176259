.FreeGiftFirstLineContainer {
    display: flex;
    flex-direction: column;

    .ErrorBox {
        display: flex;
        gap: 10px;
        margin-bottom: 0;
        color: #D72C0D;

        span {
            margin: 0;
        }
    }

    .ButtonSelection {
        &.Active {
            .Polaris-Button {
                color: white;
                background-color: #6D7175;
            }
        }
    }

    .Line {
        display: flex;
        flex-wrap: wrap;
        gap: 1ch;
        align-items: center;
        margin-bottom: 5px;

        &.Last {
            margin-bottom: 22px;
        }

        .NumberInputContainer {
            position: relative;
            float: right;
            min-width: 50px;

            .NumberInput {
                width: 100%;
                height: 36px;
                text-align: right;
                border-color: rgb(186 191 195 / 100%);
            }

            &.Invalid {
                .NumberInput {
                    background-color: #FFF4F4;
                    border: 1px solid #D82C0D;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }

        .Polaris-TextField__Input {
            width: 100%;
        }

        .Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item {
            z-index: 0;
        }
    }
}