.TranslateSection {
  .Background {
    margin-top: var(--p-space-400);
    padding: var(--p-space-200) var(--p-space-400);
    background: var(--p-color-bg-surface-secondary);
    border-top: 1px solid var(--p-color-border);
  }

  .InputSection {
    padding: var(--p-space-400) var(--p-space-400) var(--p-space-400);
    border-top: 1px solid var(--p-color-border);
  }

  .Label {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .PaddingTop {
    padding-top: var(--p-space-400);
  }

  .PaddingBottomDelete {
    padding: var(--p-space-400) var(--p-space-400) 0;
  }

  .Gap {
    margin-left: 8px;
  }
}
