.DN-CodeMirror {
  .cm-editor {
    margin-top: 4px;
    padding: 8px 12px;
    background: #fff;
    border: 1px solid #8a8a8a;
    border-radius: var(--p-border-radius-200);
  }

  &.Disabled {
    .cm-editor {
      color: var(--p-color-text-disabled);
      background: var(--p-color-bg-surface-disabled);
      border: none;
    }
  }

  &.Error {
    .cm-editor {
      border-color: var(--p-color-border-critical-secondary);
      background: var(--p-color-bg-surface-critical);
    }
  }

  .cm-gutters {
    display: none;
  }

  .cm-focused {
    outline: blue 1.5px solid;
    outline-offset: 1px;
  }
}
