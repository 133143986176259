.GrayBoxContainer {
  padding: 20px;
  background: #f6f6f7;
  border-radius: 8px;

  .SearchboxContainer {
    margin-bottom: 20px;
  }

  &.addSpace {
    padding-bottom: 20px;

    .Last {
      margin-bottom: 0 !important;
    }
  }

  .Polaris-LegacyCard__Subsection {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid rgb(225 227 229 / 100%);
  }

  .ButtonSelection {
    &.Active {
      .Polaris-Button {
        color: white;
        background-color: #6d7175;
      }
    }
  }

  .NumberInputContainer {
    position: relative;
    float: right;
    min-width: 50px;

    .NumberInput {
      width: 100%;
      height: 36px;
      text-align: right;
      border-color: rgb(186 191 195 / 100%);
    }

    &.Money {
      width: 100%;
      min-width: 75px;

      .NumberInput {
        text-align: right;
      }
    }

    &.Invalid {
      .NumberInput {
        background-color: #fff4f4;
        border: 1px solid #d82c0d;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .Line {
    display: flex;
    flex-wrap: wrap;
    gap: 1ch;
    align-items: center;
    margin-bottom: 10px;

    &.Last {
      margin-bottom: 20px;
    }
  }

  .Polaris-TextField__Input {
    width: 100%;
  }

  .Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item {
    z-index: 0;
  }
}

.Hover {
  text-decoration: underline;

  svg {
    margin-left: 4px;
    color: #5c5f62;
  }
}
