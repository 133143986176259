.StyledTabsWrapper {
  .Polaris-Box {
    padding: 3px 0;
    background: var(--p-color-bg);
    border-radius: var(--p-border-radius-150);

    .Polaris-Tabs__Tab {
      margin: 0;
    }

    .Polaris-Tabs__Tab--active {
      margin-top: 0;
      background: white;
    }
  }
}
