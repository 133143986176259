/* stylelint-disable */
.mockupPreviewPriceWidgetTemplate {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--p-color-bg-surface);
  overflow: hidden;
}

.mockupPreviewTemplateDesktop {
  position: relative;
  width: 1032px;
  height: 760px;
  overflow: hidden;
  border: 8px solid #babec2;
  border-radius: 32px;

  .imageWrapper {
    position: relative;
    display: grid;
    width: 100%;
    height: 300px;
    margin-bottom: 6px;
    background: var(--p-color-bg-fill-tertiary);
    border-radius: 8px;
    place-items: center;

    img {
      width: 15%;
      min-width: 16px;
      max-width: 42px;
      height: auto;
    }
  }

  .mainItem {
    width: 100%;
    padding: 20px 20px 0;

    .imageWrapper {
      position: relative;
      display: grid;
      width: 100%;
      height: 300px;
      margin-bottom: 0;
      background: var(--p-color-bg-fill-tertiary);
      border-radius: 8px;
      place-items: center;

      img {
        width: 15%;
        min-width: 16px;
        max-width: 42px;
        height: auto;
      }
      .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
        position: absolute;
        bottom: 53px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .Polaris-SkeletonBodyText {
          width: 480px;
          height: 8px;
          margin-top: var(--p-space-150);
          background-color: var(--p-color-bg-surface);

          &:first-child {
            width: 150px;
            height: 16px;
            margin-top: var(--p-space-800);
            margin-bottom: var(--p-space-150);
          }

          &:last-child {
            width: 350px;
          }
        }
      }
    }
    .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .Polaris-SkeletonBodyText {
        width: 480px;
        height: 8px;
        margin-top: var(--p-space-150);

        &:first-child {
          width: 150px;
          height: 16px;
          margin-top: var(--p-space-800);
          margin-bottom: var(--p-space-150);
        }

        &:last-child {
          width: 350px;
        }
      }
    }
  }

  .browserMenu {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 20px;
    background: #fff;

    .windowButton {
      width: 13px;
      height: 12px;
      margin-right: 8px;
      border-radius: 13px;

      &.isRed {
        background: #ee6a5f;
        border-color: #c7493e;
      }

      &.isYellow {
        background: #f4be50;
        border-color: #cd9b35;
      }

      &.isGreen {
        background: #5fc454;
        border-color: #39a430;
      }
    }

    .urlBlock {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 317px;
      height: 28px;
      margin: 0 auto;
      color: var(--p-color-text-secondary);
      background: #f6f6f7;
      border-radius: 5px;

      .Polaris-Icon {
        margin: 0;
      }
    }
  }

  .previewBody {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: #fff;

    .drawerCartModal {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(0 0 0 / 60%);
      z-index: 5;

      .modalWindow {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        width: 400px;
        height: 100%;
        padding: 20px;
        background: var(--p-color-bg-surface);

        .firstColumnLine {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding-bottom: 12px;

          .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
            width: 40%;
          }

          .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:last-child:not(:first-child) {
            margin-left: auto;
            width: 5%;
          }

          .Polaris-SkeletonBodyText {
            width: 100%;
            margin-top: 0 !important;
          }
        }

        .section {
          .imageWrapper {
            position: relative;
            display: grid;
            background: var(--p-color-bg-fill-tertiary);
            border-radius: var(--p-border-radius-300);
            place-items: center;

            img {
              width: 30%;
              min-width: 16px;
              max-width: 42px;
              height: auto;
            }
          }

          .spaceBetweenLine {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-bottom: 0px;

            .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
              width: 75%;
            }

            .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:last-child:not(:first-child) {
              margin-left: auto;
              width: 10%;
            }

            .Polaris-SkeletonBodyText {
              width: 100%;
              margin-top: 0 !important;
            }
          }
        }

        .seed {
          width: 45px;

          &.extra {
            width: 60px;

            .Polaris-SkeletonBodyText {
              height: 16px !important;
            }
          }

          .Polaris-SkeletonBodyText {
            width: 100%;
            margin-top: 0 !important;
            height: 20px !important;
            border-radius: var(--p-border-radius-300) !important;
          }
        }

        .Polaris-SkeletonBodyText:last-child:not(:first-child) {
          width: 70%;
        }

        .Polaris-SkeletonBodyText {
          height: var(--p-space-400);

          &:last-child {
            margin-top: 8px;
          }
        }
      }
    }

    .limoniapps-discountninja-headersection {
      width: 100%;
    }

    .actionButtons {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 12px 20px;
      border-top: 1px solid #e1e3e5;
      border-bottom: 1px solid #e1e3e5;

      .Polaris-Text--headingMd {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .mainContent {
      order: 2;
      width: 100%;
      padding: 12px 20px 0;

      .singleLineAfterColumns {
        padding-top: 12px;

        .Polaris-SkeletonBodyText {
          width: 20%;
        }
      }

      .columns {
        display: flex;
        gap: 20px;
        width: 100%;

        &.mobile {
          flex-direction: column;
        }

        .column {
          flex: 1;

          .block {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-bottom: 32px;

            .blockLine {
              width: 100%;
              display: flex;
              align-items: flex-start;
              gap: 20px;

              .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
                width: 3.5%;
              }

              .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:last-child:not(:first-child) {
                width: 45%;
              }

              &.short {
                .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:last-child:not(:first-child) {
                  width: 30%;
                }
              }

              .Polaris-SkeletonBodyText {
                width: 100%;
                margin-top: 0 !important;
              }
            }
          }

          .seed {
            width: 45px;

            &.extra {
              width: 60px;

              .Polaris-SkeletonBodyText {
                height: 16px !important;
              }
            }

            .Polaris-SkeletonBodyText {
              width: 100%;
              margin-top: 0 !important;
              height: 24px !important;
              border-radius: var(--p-border-radius-300) !important;
            }
          }

          .spaceBetweenLineSecondColumn {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-bottom: 12px;
            padding-top: 8px;

            &.checkout {
              padding-bottom: 20px;
              padding-top: 0px;

              .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
                width: 25%;
              }

              .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:last-child:not(:first-child) {
                margin-left: auto;
                width: 25%;
              }
            }

            .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
              width: 25%;
            }

            .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:last-child:not(:first-child) {
              margin-left: auto;
              width: 30%;
            }

            .Polaris-SkeletonBodyText {
              width: 100%;
              margin-top: 0 !important;
            }
          }

          .section {
            .imageWrapper {
              position: relative;
              display: grid;
              background: var(--p-color-bg-fill-tertiary);
              border-radius: var(--p-border-radius-300);
              place-items: center;

              img {
                width: 30%;
                min-width: 16px;
                max-width: 42px;
                height: auto;
              }
            }

            .spaceBetweenLine {
              width: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              padding-bottom: 0px;

              .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
                width: 75%;
              }

              .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:last-child:not(:first-child) {
                margin-left: auto;
                width: 10%;
              }

              .Polaris-SkeletonBodyText {
                width: 100%;
                margin-top: 0 !important;
              }
            }
          }

          .singleCartLine {
            padding: 8px 0 20px;

            .Polaris-SkeletonBodyText {
              width: 30%;

              &:last-child {
                margin-top: 0;
              }
            }
          }

          .spaceBetweenLine {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 12px;

            .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:last-child:not(:first-child) {
              .Polaris-SkeletonBodyText {
                margin-left: auto;
              }
            }

            .Polaris-SkeletonBodyText {
              width: 28%;
              margin-top: 0 !important;
            }
          }

          .singleLine {
            padding: 20px 0 12px;

            .Polaris-SkeletonBodyText {
              width: 20%;

              &:last-child {
                margin-top: 0;
              }
            }
          }

          .multiLines {
            padding-bottom: 12px;

            .Polaris-SkeletonBodyText:last-child:not(:first-child) {
              width: 45%;
            }

            /* stylelint-disable no-descending-specificity */
            .Polaris-SkeletonBodyText + .Polaris-SkeletonBodyText {
              margin-top: var(--p-space-200);
            }

            .Polaris-SkeletonBodyText {
              height: var(--p-space-200);
            }
          }

          .Polaris-SkeletonBodyText:last-child:not(:first-child) {
            width: 70%;
          }

          .Polaris-SkeletonBodyText {
            height: var(--p-space-400);

            &:last-child {
              margin-top: 8px;
            }
          }
        }
      }
    }

    .gridBox {
      display: flex;
      flex-wrap: wrap;
      order: 3;
      width: 100%;
      height: 100%;
      padding: 12px 20px;

      .item {
        width: 100%;
        margin-right: auto;
        margin-bottom: 12px;

        &.isCollectionPage {
          width: calc(33.3% - 8px);
          margin-right: auto;
          margin-bottom: 12px;
          .imageWrapper {
            height: 180px;
          }
        }

        .Polaris-SkeletonBodyText:last-child:not(:first-child) {
          width: 20%;
        }

        .priceWidgetPlaceHolder {
          width: 15%;
          margin-left: 32px;

          .Polaris-SkeletonBodyText {
            height: var(--p-space-200);

            &:last-child {
              margin-top: 0px !important;
            }
          }
        }

        .Polaris-SkeletonBodyText {
          height: var(--p-space-200);

          &:last-child {
            margin-top: 6px;
          }
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}

.mockupPreviewTemplateMobile {
  position: relative;
  width: 360px;
  height: 724px;
  overflow: hidden;
  border: 8px solid #babec2;
  border-radius: 32px;
  background: #fff;

  .mainItem {
    width: 100%;
    padding: 12px 12px 0;

    .imageWrapper {
      position: relative;
      display: grid;
      width: 100%;
      height: 260px;
      margin-bottom: 0;
      background: var(--p-color-bg-fill-tertiary);
      border-radius: 8px;
      place-items: center;

      img {
        width: 11%;
        min-width: 16px;
        max-width: 42px;
        height: auto;
      }
    }

    .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .Polaris-SkeletonBodyText {
        width: 100%;
        height: 8px;
        margin-top: var(--p-space-150);

        &:first-child {
          width: 150px;
          height: 16px;
          margin-top: var(--p-space-500);
          margin-bottom: var(--p-space-150);
        }

        &:last-child {
          width: 90px;
        }
      }
    }
  }

  .imageWrapper {
    position: relative;
    display: grid;
    width: 100%;
    height: 180px;
    margin-bottom: 6px;
    background: var(--p-color-bg-fill-tertiary);
    border-radius: 8px;
    place-items: center;

    img {
      width: 11%;
      min-width: 16px;
      max-width: 42px;
      height: auto;
    }
  }

  .browserMenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px 8px 8px;
    background: #fff;

    .deviceMenu {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 6px;
      padding: 0 8px;
      opacity: 0.5;
    }

    .urlBlock {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 28px;
      color: var(--p-color-text-secondary);
      background: #f6f6f7;
      border-radius: 5px;

      .Polaris-Icon {
        margin: 0;
      }
    }
  }

  .previewBody {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: #fff;

    .drawerCartModal {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(0 0 0 / 60%);
      z-index: 1;

      .modalWindow {
        position: absolute;
        top: 12px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 12px;
        background: var(--p-color-bg-surface);
        border-radius: var(--p-border-radius-400) var(--p-border-radius-400) 0 0;

        .firstColumnLine {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding-bottom: 12px;

          .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
            width: 40%;
          }

          .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:last-child:not(:first-child) {
            margin-left: auto;
            width: 5%;
          }

          .Polaris-SkeletonBodyText {
            width: 100%;
            margin-top: 0 !important;
          }
        }

        .section {
          .imageWrapper {
            position: relative;
            display: grid;
            background: var(--p-color-bg-fill-tertiary);
            border-radius: var(--p-border-radius-300);
            place-items: center;

            img {
              width: 30%;
              min-width: 16px;
              max-width: 42px;
              height: auto;
            }
          }

          .spaceBetweenLine {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-bottom: 0px;

            .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
              width: 75%;
            }

            .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:last-child:not(:first-child) {
              margin-left: auto;
              width: 15%;
            }

            .Polaris-SkeletonBodyText {
              width: 100%;
              margin-top: 0 !important;
            }
          }
        }

        .seed {
          width: 45px;

          &.extra {
            width: 60px;

            .Polaris-SkeletonBodyText {
              height: 16px !important;
            }
          }

          .Polaris-SkeletonBodyText {
            width: 100%;
            margin-top: 0 !important;
            height: 20px !important;
            border-radius: var(--p-border-radius-300) !important;
          }
        }

        .Polaris-SkeletonBodyText:last-child:not(:first-child) {
          width: 70%;
        }

        .Polaris-SkeletonBodyText {
          height: var(--p-space-400);

          &:last-child {
            margin-top: 8px;
          }
        }
      }
    }

    .limoniapps-discountninja-headersection {
      width: 100%;
    }

    .actionButtons {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 12px;
      border-top: 1px solid #e1e3e5;
      border-bottom: 1px solid #e1e3e5;

      .Polaris-Text--headingMd {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .mainContent {
      order: 2;
      width: 100%;
      padding: 12px 12px 0;

      .columns {
        height: 100%;
        width: 100%;

        .column {
          flex: 1;

          .block {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-bottom: 32px;

            .blockLine {
              width: 100%;
              display: flex;
              align-items: flex-start;
              gap: 20px;

              .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
                width: 5%;
              }

              .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:last-child:not(:first-child) {
                width: 45%;
              }

              &.short {
                .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:last-child:not(:first-child) {
                  width: 30%;
                }
              }

              .Polaris-SkeletonBodyText {
                width: 100%;
                margin-top: 0 !important;
              }
            }
          }

          .seed {
            width: 45px;

            &.extra {
              width: 60px;

              .Polaris-SkeletonBodyText {
                height: 16px !important;
              }
            }

            .Polaris-SkeletonBodyText {
              width: 100%;
              margin-top: 0 !important;
              height: 20px !important;
              border-radius: var(--p-border-radius-300) !important;
            }
          }

          .spaceBetweenLineSecondColumn {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-bottom: 12px;
            padding-top: 8px;

            &.checkout {
              padding-bottom: 20px;
              padding-top: 0px;

              .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
                width: 25%;
              }

              .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:last-child:not(:first-child) {
                margin-left: auto;
                width: 25%;
              }
            }

            .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
              width: 25%;
            }

            .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:last-child:not(:first-child) {
              margin-left: auto;
              width: 30%;
            }

            .Polaris-SkeletonBodyText {
              width: 100%;
              margin-top: 0 !important;
            }
          }

          .section {
            .imageWrapper {
              position: relative;
              display: grid;
              background: var(--p-color-bg-fill-tertiary);
              border-radius: var(--p-border-radius-300);
              place-items: center;

              img {
                width: 30%;
                min-width: 16px;
                max-width: 42px;
                height: auto;
              }
            }

            .spaceBetweenLine {
              width: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              padding-bottom: 0px;

              .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
                width: 75%;
              }

              .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:last-child:not(:first-child) {
                margin-left: auto;
                width: 15%;
              }

              .Polaris-SkeletonBodyText {
                width: 100%;
                margin-top: 0 !important;
              }
            }
          }

          .singleCartLine {
            padding: 0 0 12px;

            .Polaris-SkeletonBodyText {
              width: 30%;

              &:last-child {
                margin-top: 0;
              }
            }
          }

          .spaceBetweenLine {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 12px;

            .Polaris-SkeletonBodyText__SkeletonBodyTextContainer:last-child:not(:first-child) {
              .Polaris-SkeletonBodyText {
                margin-left: auto;
              }
            }

            .Polaris-SkeletonBodyText {
              width: 28%;
              margin-top: 0 !important;
            }
          }

          .singleLine {
            padding: 20px 0 12px;

            .Polaris-SkeletonBodyText {
              width: 20%;

              &:last-child {
                margin-top: 0;
              }
            }
          }

          .multiLines {
            padding-bottom: 12px;

            .Polaris-SkeletonBodyText:last-child:not(:first-child) {
              width: 45%;
            }

            .Polaris-SkeletonBodyText + .Polaris-SkeletonBodyText {
              margin-top: var(--p-space-200);
            }

            .Polaris-SkeletonBodyText {
              height: var(--p-space-200);
            }
          }

          .Polaris-SkeletonBodyText:last-child:not(:first-child) {
            width: 70%;
          }

          .Polaris-SkeletonBodyText {
            height: var(--p-space-400);

            &:last-child {
              margin-top: 8px;
            }
          }
        }
      }

      .multiLines {
        padding-bottom: 24px;

        .Polaris-SkeletonBodyText:last-child:not(:first-child) {
          width: 45%;
        }

        .Polaris-SkeletonBodyText + .Polaris-SkeletonBodyText {
          margin-top: var(--p-space-150);
        }

        .Polaris-SkeletonBodyText {
          height: var(--p-space-200);
        }
      }

      .singleLineAfterColumns {
        .Polaris-SkeletonBodyText {
          width: 25%;
        }
      }

      .raw {
        .Polaris-SkeletonBodyText:last-child:not(:first-child) {
          width: 70%;
        }

        .Polaris-SkeletonBodyText {
          height: var(--p-space-400);

          &:last-child {
            margin-top: 8px;
          }
        }
      }
    }

    .gridBox {
      display: flex;
      flex-wrap: wrap;
      order: 3;
      width: 100%;
      height: 100%;
      padding: 12px;

      .item {
        width: 100%;
        margin-bottom: 12px;

        &.isCollectionPage {
          .imageWrapper {
            height: 180px;
          }
        }

        .imageWrapper {
          height: 260px;
        }

        .priceWidgetPlaceHolder {
          width: 15%;
          margin-left: 32px;

          .Polaris-SkeletonBodyText {
            height: var(--p-space-200);

            &:last-child {
              margin-top: 0px !important;
            }
          }
        }

        .Polaris-SkeletonBodyText:last-child:not(:first-child) {
          width: 15%;
        }

        .Polaris-SkeletonBodyText {
          height: var(--p-space-200);

          &:last-child {
            margin-top: 5px;
          }
        }

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

.Polaris-SkeletonBodyText {
  border-radius: 8px !important;
}
