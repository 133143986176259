.TierHeader {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  color: #1A202C;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  background: #F6F6F7;
  border-radius: 4px;

  &Info {
    display: flex;

    &BoldPart {
      font-weight: 600;
    }

    span {
      font-weight: 600;
    }
  }

  .TrashBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #D82C0D;
    cursor: pointer;

    span {
      margin-left: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.TierBody {
  display: flex;
  padding-top: 24px;

  &>* {
    flex: 1;
    min-width: 0;
    margin-left: 0.875em;
  }

  &>*:first-child {
    margin-left: 0;
  }

  .BogoType {
    flex: 0.5;
  }

  .Polaris-TextField__Spinner {
    display: none;
  }

  .Invalid {
    .Polaris-TextField__Backdrop {
      background-color: #FFF4F4;
      border-color: #D82C0D;
    }
  }

  .TierBodyLink {
    padding-top: 13px;

    .ActivePart {
      color: #2C6ECB;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.TierErrorBoxWrapper {
  padding-top: 24px;

  &>*:last-child {
    margin-bottom: 0 !important;
  }

  .TierErrorBox {
    display: flex;
    gap: 10px;
    margin-bottom: 8px;
    color: #D72C0D;

    span {
      margin: 0;
    }
  }
}