.CanCombineLink {
  text-decoration: none;

  .Polaris-Icon {
    margin: 0;
    margin-left: 8px;
    fill: #2c6ecb;
  }

  .Polaris-Button__Content {
    flex-direction: row-reverse;
  }
}

.TableModal {
  h5,
  p {
    font-size: 14px;
  }

  .renderedTable {
    position: relative;
    min-height: 422px;

    .hasGroup {
      width: fit-content;
      margin-top: 10px;
      padding: 2px 8px;
      font-weight: 400;
      font-size: 13px;
      background: #e4e5e7;
      border-radius: 10px;
    }

    .BoldTitle {
      font-weight: 600;
      font-size: 14px;
    }

    .tableLoader {
      position: absolute;
      z-index: 101;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(235 236 239 / 70%);
    }
  }

  .Polaris-DataTable__Cell--header {
    font-weight: 500;
  }

  .Polaris-Text--subdued {
    font-weight: 400;
    font-size: 12px;
  }

  .Polaris-LegacyCard__Section {
    border-top: 1px solid #e5e5e5;
  }

  nav {
    .Polaris-ButtonGroup {
      .Polaris-ButtonGroup__Item:last-child {
        button {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      .Polaris-ButtonGroup__Item:first-child {
        button {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}
