.modalSectionWrapper {
    .Polaris-Modal-Section {
        &>.Polaris-Box {
            padding: 0
        }

        .Polaris-IndexFilters-Container {
            border: 0
        }

        .Polaris-IndexFilters__ActionWrap {
            padding: var(--p-space-150) var(--p-space-300) var(--p-space-150) var(--p-space-200);
        }

        .listItem {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: var(--p-space-300) var(--p-space-400);
            border-bottom: 1px solid var(--p-color-border);
            cursor: pointer;

            &:first-of-type {
                border-top: 1px solid var(--p-color-border);
            }

            &:hover {
                background: var(--p-color-bg-surface-hover);
            }

            .menuHorizontalIcon {
                &>div {
                    display: flex;
                }
            }
        }

        .emptyState {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 120px;
            border-bottom: 1px solid var(--p-color-border);
        }

        .presetsListPagination {
            padding: var(--p-space-400);
            background: var(--p-color-bg-surface-secondary);

            .Polaris-ButtonGroup {
                justify-content: flex-end;
            }
        }
    }

}