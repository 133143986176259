.ConfigGoalsAndDiscountsTitle {
  margin-bottom: 4px;
  color: #202223;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}

.TiersContainer {
  padding-top: 25px;

  .TierWrapper {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #E1E3E5;
  }
}