.LeftSideBarWidgetList {
  display: flex;
  flex-direction: column;
  margin-top: var(--p-space-300);

  .PageGroupList {
    .WidgetOption {
      margin-top: 0;
    }
  }

  .PageGroupNameBox {
    display: flex;
    gap: var(--p-space-200);
    align-items: center;
    padding: var(--p-space-200) var(--p-space-100);
  }

  .AddWidgetButton {
    display: flex;
    align-items: center;
    margin-left: 28px;
    padding: var(--p-space-200) var(--p-space-100);

    .Polaris-Button--textAlignCenter {
      gap: var(--p-space-200);
    }
  }
}

.AddWidgetListLoaderBox {
  width: 120px;
  height: 80px;
}
