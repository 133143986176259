.SizeWidthSetting {
  .BreakPointsList {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    .BreakPointRow {
      display: flex;
      gap: 8px;
      align-items: flex-end;
      width: 100%;
    margin-bottom: 12px;

      .BreakpointsInput {
        width: 100%;
      }

      .Polaris-Label__Text {
        color: #6d7175;
        font-size: 11px;
      }
    }

    .Polaris-TextField__Spinner {
      display: none;
    }

    .Polaris-Button--plain {
      padding-left: 0;
    }

    .RemoveBreakpoint {
      .Polaris-Button {
        border: none;
        box-shadow: none;
        opacity: 0;
      }

      &:hover {
        .Polaris-Button {
          opacity: 100;
        }
      }
    }
  }
}
