.SupportDetailsModal {
  width: 620px;

  .SupportDetailsModalContent {
    max-height: 85vh;
    padding: var(--p-space-400);
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .WidgetModeListBox {
      padding: var(--p-space-300);
      border: 1px solid var(--p-color-border);
      border-radius: 12px;
    }

    .NoteBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--p-space-300);
      background: var(--p-color-bg);
      border-radius: 12px;
    }

    .NoteInputBox {
      display: flex;
      gap: var(--p-space-300);
      width: 100%;

      > div {
        width: 100%;
      }
    }
  }

  .ModalFooterActions {
    padding: var(--p-space-400);
    border-top: var(--size-025, 1px) solid var(--p-color-border);
  }
}
