.SmartTagSettings {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: white;

  .SmartTagSettingsContent {
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .SmartTagSettingsFooter {
    z-index: 101;
    width: 100%;
    margin-bottom: 60px;
    padding: var(--p-space-400) var(--p-space-500) 23px var(--p-space-500);
    background-color: white;
    border-top: 1px solid #e1e3e5;
  }
}
