.UserBillingSummary {
  .PlanNavigationCard {
    padding: var(--p-space-400);
    cursor: pointer;
    transition: 50ms all ease-in-out;

    &:hover {
      background-color: var(--p-color-bg-surface-hover);
    }
  }
}
