.RecurringScheduleGridBox {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: var(--p-space-400);
  grid-row-gap: var(--p-space-400);

  &.AdvancedSelection {
    #RecurrenceAdvancedSelector {
      grid-area: 1 / 1 / 2 / 3;
    }
    #RecurrenceStartTime {
      grid-area: 1 / 3 / 2 / 4;
    }
    #RecurrenceEndTime {
      grid-area: 1 / 4 / 2 / 5;
    }
  }

  #RecurrenceStartTime {
    grid-area: 1 / 1 / 2 / 2;
  }
  #RecurrenceEndTime {
    grid-area: 1 / 2 / 2 / 3;
  }
  #RecurrenceStartDate {
    grid-area: 2 / 1 / 3 / 3;
  }
  #RecurrenceEndDate {
    grid-area: 2 / 3 / 3 / 5;
  }
}
