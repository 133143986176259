.IconButton {
  padding: 6px;
  border-radius: var(--p-border-radius-200);
  cursor: pointer;

  &.DefaultTone {
    &:hover {
      background-color: var(--p-color-bg-surface-hover);
    }
  }
}
