.Background {
  .BackgroundRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .RightContent {
      width: 200px;

      .LineWeightInputs {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }

    .LineWeightBox {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .Polaris-Text--root {
        margin-top: 5px;
      }
    }

    .BorderSides {
      display: flex;
      gap: 2px;

      .BorderSide {
        width: 20px;
        height: 20px;
        margin: 6px;
        border: 1px dashed var(--p-color-icon-disabled);

        &.isDisabled {
          border-color: #e1e3e5;
          pointer-events: none;

          &.isActive {
            border-color: var(--p-color-icon);
          }
        }
      }

      .ALL {
        border: 2px solid var(--p-color-icon-disabled);

        &.isActive {
          border: 2px solid var(--p-color-icon);
        }
      }

      .TOP {
        border-top: 2px solid var(--p-color-icon-disabled);

        &.isActive {
          border: 1px dashed #8c9196;
          border-top: 2px solid var(--p-color-icon);
        }
      }

      .RIGHT {
        border-right: 2px solid var(--p-color-icon-disabled);

        &.isActive {
          border: 1px dashed #8c9196;
          border-right: 2px solid var(--p-color-icon);
        }
      }

      .BOTTOM {
        border-bottom: 2px solid var(--p-color-icon-disabled);

        &.isActive {
          border: 1px dashed #8c9196;
          border-bottom: 2px solid var(--p-color-icon);
        }
      }

      .LEFT {
        border-left: 2px solid var(--p-color-icon-disabled);

        &.isActive {
          border: 1px dashed #8c9196;
          border-left: 2px solid var(--p-color-icon);
        }
      }

      .MIDDLE {
        position: relative;

        &::before {
          position: absolute;
          bottom: -1px;
          left: 8px;
          width: 2px;
          height: 20px;
          background-color: var(--p-color-icon-disabled);
          content: '';
        }

        &.isActive {
          &::before {
            background-color: var(--p-color-icon);
          }

          border: 1px dashed #8c9196;
        }
      }

      .BackgroundSide {
        border-radius: 8px;
        cursor: pointer;
        transition: 0.05s all ease-in-out;

        &.isActive {
          background-color: var(--p-color-bg-surface-selected);
        }

        &:hover {
          background-color: var(--p-color-bg-surface-selected);

          .BorderSide {
            border: 1px dashed #8c9196;
          }

          .ALL {
            border: 2px solid var(--p-color-icon-active) !important;
          }

          .TOP {
            border-top: 2px solid var(--p-color-icon-active) !important;
          }

          .RIGHT {
            border-right: 2px solid var(--p-color-icon-active) !important;
          }

          .BOTTOM {
            border-bottom: 2px solid var(--p-color-icon-active) !important;
          }

          .LEFT {
            border-left: 2px solid var(--p-color-icon-active) !important;
          }

          .MIDDLE {
            &::before {
              background-color: var(--p-color-icon-active) !important;
            }
          }
        }
      }
    }
  }
}