.CrossSellDiscountModal {
  position: fixed;
  top: 0;
  left: 60px;
  z-index: 200;
  width: calc(100% - 60px);
  height: 100%;
  padding: var(--p-space-200) var(--p-space-200) 0;
  background: rgb(26 26 26 / 60%);

  .CrossSellDiscountModalContent {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--p-color-bg-surface-secondary);
    border-radius: 12px 12px 0 0;

    .CrossSellDiscountModalHeader {
      padding: var(--p-space-400);
      background: var(--p-color-bg-surface);
    }

    .CrossSellDiscountModalBody {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;

      .CrossSellDiscountModalBodyContent {
        width: 100%;
        max-width: 70%;
        height: 90%;
        overflow-y: scroll;
        scrollbar-width: none;
      }
    }
  }
}

.bullet {
  padding: var(--p-space-200) var(--p-space-800) 0;
}
