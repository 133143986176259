.ShopifyAppBridgeModalInitiator {
  &Container {
    display: flex;
    flex-flow: column nowrap;
    margin-top: 22px;

    &.isCrossSell {
      margin: 12px 0 22px;
    }

    .Polaris-Banner.Polaris-Banner--statusInfo {
      margin-top: 20px;
      border: 1px solid #98c6cd;
      border-radius: 8px;

      .header {
        margin-bottom: 4px;
        font-weight: 600;
        font-size: 16px;
        font-style: normal;
      }

      .body {
        font-weight: 400;
        font-size: 14px;
        font-style: normal;
      }
    }

    &.Invalid {
      .ShopifyAppBridgeModalInitiatorSearchField {
        background: #fff4f4;
        border-color: #fd5749;
      }

      &>p {
        display: flex;
        gap: 10px;
        color: #d72c0d;

        span {
          margin: 0;
        }
      }
    }

    /* stylelint-disable no-descending-specificity */
    &>p {
      /* stylelint-enable no-descending-specificity */
      margin: 4px 0 0;
      color: #6d7175;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

  /* stylelint-disable no-descending-specificity */
  &SearchField {
    /* stylelint-enable no-descending-specificity */
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    justify-content: flex-start;
    padding: 8px 12px;
    background: #fff;
    border: 1px solid #babfc3;
    border-radius: 4px;
    cursor: pointer;

    &Filled {
      gap: 0;
      justify-content: space-between;
      padding: 0 12px 4px;
      cursor: default;

      &>button.Polaris-Button {
        align-self: flex-start;
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
        margin: 8px 0 0 8px;
        padding: 0;
        background: transparent;
        border: none;
        box-shadow: none;
      }
    }

    .TagsContainer {
      display: flex;
      flex-flow: row wrap;
    }

    .Polaris-Icon {
      margin: 0;
    }

    &Placeholder {
      margin: 0;
      color: #6d7175;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    &Tag {
      display: flex;
      flex-flow: row nowrap;
      gap: 4px;
      margin: 4px 8px 0 0;
      padding: 4px 8px;
      background: #e4e5e7;
      border-radius: 8px;

      &.ShowMore {
        color: #2c6ecb;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
      }

      /* stylelint-disable no-descending-specificity */
      span {
        /* stylelint-enable no-descending-specificity */
        color: #202223;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
      }

      button.Polaris-Button {
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
        padding: 0;
        background: transparent;
        border: none;
        box-shadow: none;

        &::after {
          display: none;
        }
      }
    }
  }
}

.SelectedVariantsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  &>button.Polaris-Button {
    align-self: flex-start;
    margin-top: 14px;
  }

  h3 {
    margin: 0;
    padding: 0;
    color: #202223;
    font-weight: 600;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    text-transform: uppercase;
  }

  .SelectedVariantTile {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    gap: 12px;
    align-items: center;
    width: 100%;
    margin-top: 12px;
    padding: 4px 16px;
    text-decoration: none !important;
    border-radius: 4px;

    &.Hover {
      background: #f1f2f3;
    }

    .orderBtns {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;

      .btn {
        cursor: pointer;
      }

      .btn:last-of-type {
        margin-left: 12px;
      }
    }

    .dragHandleMinor {
      position: absolute;
      top: 50%;
      left: -5px;
      transform: translateY(-50%);
    }

    .SelectedVariantTitle {
      display: flex;
      flex-direction: column;
      color: #202223;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      span {
        color: #6d7175;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .Polaris-Thumbnail {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      border: none;
      border-radius: 4px;

      img {
        width: 100%;
        height: 100%;
        margin: 0;
        object-fit: cover;
      }
    }
  }
}