.CombineWithModalTile {
    display: flex;
    align-items: center;

    h5 {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }

    .Polaris-Choice {
        width: 18px;
        margin-right: 20px;
    }

    .hasGroup {
        width: fit-content;
        margin-left: 24px;
        padding: 0 8px;
        font-weight: 400;
        font-size: 13px;
        background: #E4E5E7;
        border-radius: 10px;
    }
}