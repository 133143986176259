.GroupComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  .connectorSelector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 106px;
    padding: 7px 12px;
    background: #fff;
    border: 1px solid #e1e3e9;
    border-radius: 4px;
    cursor: pointer;

    .connector {
      font-weight: 400;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
    }
  }

  .connectorBlock {
    flex: 1
  }

  .defaultConnectorBlock {
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    white-space: nowrap;
  }

  .conditionsBlock {
    flex: 0 0 calc(100% - 154px);
    width: 100%;

    .Condition:last-of-type {
      margin-bottom: 0;
    }
  }
}