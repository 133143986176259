.NavigationBox {
  &.isHoverable {
    &:hover {
      transition: all 0.07s;
      border-radius: var(--p-space-200);
      cursor: pointer;
      background: var(--p-color-bg-surface-hover);
    }
  }
}
