.WidgetInstallationModalContent {
  .ToggleWidgetModalContent {
    padding: var(--p-space-400);
  }
  .ModalFooterActions {
    position: sticky;
    display: flex;
    justify-content: end;
    padding: var(--p-space-400);
    border-top: var(--size-025, 1px) solid var(--p-color-border);
    bottom: 0px;
    z-index: 10000;
    background-color: white;
  }
}
