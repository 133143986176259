.UploadImageModalContent {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ImageDropZone {
    .Polaris-DropZone__Container {
      height: 120px;
    }

    .UploadedFiles {
      display: flex;
      flex-direction: column;
      gap: 8px;
      box-sizing: border-box;
      max-height: 120px !important;
      padding: 10px;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      .ImageRow {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
}
