.PromotionList {
  .OffersColumn {
    min-width: 96px !important;
    max-width: 96px !important;
    padding: 0 !important;
    & > div {
      height: 100%;
    }
  }
  .TokenColumn {
    min-width: 112px !important;
    max-width: 112px !important;
  }

  .StatusColumn {
    min-width: 112px !important;
    max-width: 112px !important;
  }

  .ScheduleColumn,
  .TriggerColumn,
  .PromotionTypeColumn {
    min-width: 136px !important;
    max-width: 136px !important;
  }

  .TriggerColumn {
    min-width: 140px !important;
    max-width: 140px !important;
  }

  .PromotionTypeColumn {
    min-width: 148px !important;
    max-width: 148px !important;
  }

  .ModifiedColumn {
    min-width: 130px !important;
    max-width: 130px !important;
  }

  .PromotionTagsColumn {
    padding: 0 !important;

    .TagsPopoverActivator {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-right: var(--p-space-300);
      padding-left: var(--p-space-150);
      padding-block: var(--p-space-150);
    }
  }

  .Polaris-Link {
    text-decoration: none;
  }

  .Polaris-IndexTable__TableCell {
    height: 52px;

    // Might be usefull during future Markdown promotions implementation
    // position: relative;
    // .Polaris-ButtonGroup {
    //   position: absolute;
    //   right: 19px;
    //   bottom: 10px;
    //   flex-wrap: nowrap;

    //   .Polaris-ButtonGroup__Item {
    //     margin-bottom: var(--p-space-100);
    //   }
    // }
  }

  .Polaris-IndexTable__EmptySearchResultWrapper {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .Polaris-IndexTable__TableHeading--second {
    padding: 0;
  }

  .IconActivator {
    opacity: 0;
  }

  .Polaris-IndexTable__TableRow {
    &:hover {
      .IconActivator {
        opacity: 100;
      }
    }
  }
}
