.BogoOffer {
  margin-bottom: 20px;

  .limitBlock {
    h2 {
      font-weight: 600;
      font-size: 12px;
    }

    display: inline-flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
  }

  .limitWarning {
    display: flex;
    gap: 0.6rem;
    align-items: center;
    margin-top: 12px;
    padding: 16px;
    background: #F6F6F7;
    border: 1px solid #BABFC3;
    border-radius: 8px;

    .Polaris-Icon {
      margin: 0;
    }
  }

  .checkboxWrapper {
    display: flex;
    align-items: center;
    margin-top: 12px;

    .Polaris-Choice {
      margin-top: 0;
      margin-left: 5px;
    }

    .maximumCheckboxLabel {
      display: flex;
      flex-wrap: wrap;
      gap: 1ch;
      align-items: center;

      .NumberInputContainer {
        position: relative;
        float: right;
        min-width: 50px;

        .NumberInput {
          width: 100%;
          height: 36px;
          text-align: right;
          border-color: rgb(186 191 195 / 100%);
        }

        &.Invalid {
          .NumberInput {
            background-color: #FFF4F4;
            border: 1px solid #D82C0D;

            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .ErrorBox {
    display: flex;
    gap: 6px;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 0;
    color: #D72C0D;

    span {
      width: 1.25rem;
      height: 1.25rem;
      margin: 0;
    }
  }

  .BogoTypesContainer {
    display: flex;
    flex-direction: column;

    .BogoTypeTile {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      margin-bottom: 12px;
      padding: 20px;
      background: #FFF;
      border: 1px solid #E1E3E5;
      border-radius: 4px;

      .BannerContainer {
        display: flex;
        flex-flow: column nowrap;
        min-width: 300px;
        margin-top: 16px;
        padding: 16px;
        background: #F6F6F7;
        border-radius: 8px;

        .itemWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 6px;
          font-weight: 600;
          font-size: 16px;

          span:not(:last-child) {
            margin-right: 4px;
          }

          .CROSSED_OUT {
            color: #6D7175;
            font-weight: 400;
            font-size: 14px;
            text-decoration-line: line-through;
          }

          .INFO {
            margin-left: 8px;
            font-weight: 500;
            font-size: 12px;
          }
        }

        .itemWrapper:last-of-type {
          margin-bottom: 0;
        }

        .BogoCustomErrorBoxWrapper {
          &>*:last-child {
            margin-bottom: 0 !important;
          }

          .BogoCustomErrorBox {
            display: flex;
            gap: 10px;
            align-items: center;
            margin-bottom: 8px;
            color: #D72C0D;

            span {
              margin: 0;
            }
          }
        }

        .Line {
          display: flex;
          flex-wrap: wrap;
          gap: 1ch;
          align-items: center;
          margin-bottom: 10px;

          .ActivePart {
            color: #2C6ECB;
            font-weight: 500;
            text-decoration: underline;
            cursor: pointer;
          }

          &.Last {
            margin-bottom: 22px;
          }

          .ButtonSelection {
            &.Active {
              .Polaris-Button {
                color: white;
                background-color: #6D7175;
              }
            }
          }

          .NumberInputContainer {
            position: relative;
            float: right;
            min-width: 50px;

            .NumberInput {
              width: 100%;
              height: 36px;
              text-align: right;
              border-color: rgb(186 191 195 / 100%);
            }

            &.Money {
              width: 100%;
              min-width: 75px;

              .NumberInput {
                text-align: right;
              }
            }

            &.Invalid {
              .NumberInput {
                background-color: #FFF4F4;
                border: 1px solid #D82C0D;

                &:focus {
                  box-shadow: none;
                }
              }
            }
          }

          .Polaris-TextField__Input {
            width: 100%;
          }

          .Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item {
            z-index: 0;
          }
        }

        .Line:last-of-type {
          margin-bottom: 0;
        }
      }

      .BogoTypeDataContainer {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: space-between;

        .BogoTypeTitle {
          margin-right: 21px;
          color: #1A202C;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

          .BogoTypeDescription {
            display: block;
            margin-top: 4px;
            color: #6D7175;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
          }
        }

        button.Polaris-Button {
          min-width: 75px;
          padding: 8px 16px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          border-radius: 4px;

          .Polaris-Button__Icon {
            display: none;
          }
        }
      }

      &.Active {
        border: 1.5px solid #2C6ECB;

        .BogoTypeDataContainer button.Polaris-Button {
          min-width: 110px;
          color: #fff;
          background: #2C6ECB;
          border-color: #1F5199;
          box-shadow: inset 0 2px 0 rgb(0 0 0 / 5%);

          .Polaris-Button__Icon {
            display: flex;

            svg {
              fill: #fff;
            }
          }
        }
      }
    }

    .BogoTypeTile:last-of-type {
      margin-bottom: 0;
    }
  }
}