  .ColorRow {
    display: flex;
    align-items: center;

    .RowContent{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      padding: 7px 8px;

      &:hover{
          background: #F1F2F3;
          border-radius: 2px;
      }

      .RowLabel {
        display: flex;
        gap: 8px;
      }
    }

    .DotsButton {
      .Polaris-Button {
        border: none;
        border-radius: 2px;
        box-shadow: none;
        opacity: 0;

        &:hover {
          background: #f6f6f7;
          opacity: 100;
        }
      }

      &.isActive {
        .Polaris-Button {
          background: #f1f2f3;
          opacity: 100;
        }
      }
    }

    .ColorBox {
      width: 20px;
      height: 20px;
      border: 1px solid #aeb4b9;
      border-radius: 4px;
    }
  }
