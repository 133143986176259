.SimplifiedTextEntries {
  .StyleComponentWrapper {
    padding-bottom: var(--p-space-300);
  }

  &.HideHeader {
    padding-top: 0;
  }

  .SimplifiedTextEntriesContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 var(--p-space-500) var(--p-space-500);

    .EntriesList {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .EntryRow {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .RightContent {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 200px;
      }
    }
  }
}
