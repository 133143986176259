.IconStyle {
  .IconRow {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
  }

  .RightContent {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 200px;

    .PrefixImage {
      width: 20px;
      height: 20px;
      margin-top: 5px;
    }
  }
}