.FontStyle {
  padding: 12px;

  .FontStyleHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding-left: 8px;

    .DotsButton {
      .Polaris-Button {
        border: none;
        box-shadow: none;
      }

      &.isActive {
        .Polaris-Button {
          background: #f1f2f3;
          border-radius: 2px;
        }
      }
    }

    .TipAndMoreActions{
        display: flex;
        gap: 8px;
        align-items: center;
    }
  }

  .FontStyleContent {
    display: flex;
    gap: 8px;
    padding: 0 8px;

    .Loader {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border: 1px solid #aeb4b9;
      border-radius: 4px;
      box-shadow: 0 1px 0 0 rgb(0 0 0 / 5%);
    }

    .StyleSelector {
      max-width: 100px;
    }
  }
}
