.ExploreAllWidgets {
  .Polaris-ResourceList__FiltersWrapper {
    padding: var(--p-space-400);
    .Polaris-Filters__Container {
      border: 0;
      > .Polaris-Box {
        padding: 0;
      }
    }
    .Polaris-Filters__FiltersWrapper {
      border: 0;
      padding-top: var(--p-space-400);
      .Polaris-Filters__FiltersInner {
        padding: 0;
      }
    }
  }
  .Polaris-ResourceList {
    .installationMode {
      padding: var(--p-space-400);
      border-bottom: var(--p-border-width-025) solid var(--p-color-border);

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
