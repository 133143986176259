.strike-through-pricing-root {
    display: flex;
    align-items: center;
    justify-content: center;

    &.isNotPreset {
        justify-content: flex-start;

        &.isCollectionPagePrice {
            width: 100px;
            margin-left: 32px;
        }
    }

    la-dn-price {
        cursor: pointer;
    }
}