.widgetWrapper {
  he .placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--p-color-bg-surface-brand);

    &.separate {
      .Polaris-Icon {
        width: 100%;
        height: 158px;
      }
    }
  }
  > .Polaris-InlineGrid {
    > .Polaris-BlockStack:nth-of-type(1) {
      // First BlockStack
      @media (max-width: 1040px) {
        order: 2;
      }
    }

    > .Polaris-BlockStack:nth-of-type(2) {
      // Second BlockStack
      @media (max-width: 1040px) {
        order: 1;
      }
    }

    @media (max-width: 1040px) {
      grid-template-columns: 1fr !important;
    }
  }
}
