.ColorSelectorPopover {
  width: 280px;

  .SavedColorsBoxes {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 3px;
    width: 100%;
    margin-top: 4px;

    .Color {
      cursor: pointer;
      width: 32px;
      height: 32px;
      border: 1px solid rgb(0 0 0 / 10%);
      border-radius: var(--p-space-200);
    }
  }
}
