.SearchFieldWithTags {
  .Title {
    margin-bottom: 4px;
  }

  .ShopifyAppBridgeModalInitiatorContainer {
    margin-top: 0;
  }

  .ShowMore {
    cursor: pointer;
  }

  &.Invalid {
    input.NumberInput {
      background-color: #FFF4F4;
      border: 1px solid #D82C0D;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .ErrorBox {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    color: #D72C0D;

    span {
      margin: 0;
    }
  }
}