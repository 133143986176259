.EditWidgetText {
  width: 260px;

  .EditTextHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 6px 6px 12px;
    border-bottom: 1px solid #e1e3e5;

    .Polaris-Button {
      border: none;
      box-shadow: none;
    }
  }

  .EditTextContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
  }

  .SelectorRow {
    display: flex;
    gap: 12px;
    align-items: flex-start;
    justify-content: space-between;

    .SelectorLabel {
      margin-top: 7px;
    }

    .Polaris-TextField {
      width: 100px;
    }

    .SelectorBox {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .Selectors {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  .Polaris-TextField__Spinner {
    display: none;
  }
}

.TextRow {
  display: flex;
  align-items: center;
  width: 100%;

  &>div{
    width: 100%;
  }

  .TextStylesBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: 6px 8px;
    background: var(--p-color-bg-surface);
    border: 0.66px solid var(--color-input-border, #8a8a8a);
    border-radius: 8px;

    &.isDisabled{
      color:var(--p-color-text-disabled);
      background: var(--p-color-bg-surface-disabled);
      border: 0.66px solid var(--p-color-bg-surface-disabled)
    }

    .Polaris-Text--root {
      span {
        color: var(--p-color-text-disabled);
      }
    }
  }

  .TextProperties {
    display: flex;
    gap: 8px;
    align-self: start;
  }
}
