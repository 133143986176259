.DiscountCodeSectionContainer {
  .Polaris-Banner.Polaris-Banner--statusInfo {
    border: 1px solid #98C6CD;
    border-radius: 8px;
  }
}

.ProTipContainer {
  display: flex;
  flex-flow: row;
  margin-top: 24px;

  p {
    color: #202223;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    white-space: wrap;

    &>span {
      height: 20px;
      color: #202223;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
    }
  }

  button.Polaris-Button.Polaris-Button--plain {
    top: 4px;
    min-height: 20px;
    max-height: 20px;
    margin-left: 0;
  }
}

.AdditionalText {
  display: block;
  color: #202223;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.FindOutMore {
  margin-top: 2px;
  color: #202223;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  button.Polaris-Button.Polaris-Button--plain {
    top: 4px;
    min-height: 20px;
    max-height: 20px;
    margin-left: 0;
  }
}