.ActionGroupTitle {
  color: #202223;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}

.Products {
  .bullet {
    padding: var(--p-space-200) var(--p-space-800) 0;
  }
}
